import ApiClient from '@/ApiClient'

export default {
  state() {
    return {
      spaces: []
    }
  },
  mutations: {
    setSpacesList(state, spaces) {
      state.spaces = spaces
    },
    setSpaceListItemIcon(state, { spaceUri, icon, iconset}) {
      const spaceListItem = state.spaces.find(space => space._links.self.href === spaceUri)
      spaceListItem.icon = icon
      spaceListItem.iconset = iconset
    },
    setSpaceListItemColor(state, {spaceUri, color}) {
      const spaceListItem = state.spaces.find(space => space._links.self.href === spaceUri)
      spaceListItem.color = color
    }
  },
  actions: {
    loadSpaces(context, spacesUri) {
      return ApiClient.getUri(spacesUri)
        .then(response => {
          context.commit('setSpacesList', response.data.items)
          return response.data
        })
    }
  },
  getters: {
    spacesList(state) {
      return state.spaces.map((space) => {
        return {
          uri: space._links.self.href,
          name: space.name,
          icon: space.icon,
          color: space.color,
          iconset: space.iconset,
          realSpace: space.realSpace,
          sharedBy: space.sharedBy,
          belongsTo: space.belongsTo,
          _embedded: space._embedded
        }
      }) || []
    }
  }
}
