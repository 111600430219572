<template>
  <BaseStatefulView
    class="white"
    :persistentGridUri="gridUri"
    :virtualGridUri="virtualGridUri"
    :viewTitle="statefulView ? statefulView.name : ''"
    @reloadView="reloadView"
    @toggledDrawer="onResize"
    :showToolbar="showToolbar"
    :embedded="embedded"
    :autoreload="autoreload"
    
  >
    <template
      v-slot:toolbar-left
      v-if="!loading && virtualGrid"
      v-show="!$vuetify.breakpoint.mobile"
    >
      <GalleryCardSettings
        :virtualGrid="virtualGrid"
        :statefulViewUri="statefulViewUri"
        :disabled="!canPatchView"
        />

      <VirtualGridToolbar
        :virtualGrid="virtualGrid"
        :statefulViewUri="statefulViewUri"
        @filterMouseEnter="highlightFilteredColumns = true"
        @filterMouseLeave="highlightFilteredColumns = false"
        :labels="toolbarLabels"
      />
    </template>
    <div
      
      v-if="!loading && virtualGrid && statefulView "
      class="d-flex  full-height justify-center align-center "
      ref="scroller"
    >
    <v-virtual-scroll
      v-resize="onResize"
      :items="cardsModel"
      :item-height="itemHeight"
      bench="2"
      :key="virtualGrid.scrollerKey"
      >
      <template v-slot="{ item }">
        <div class="d-flex" :class="item.length < columns ? 'justify-start' : 'justify-space-around' ">
          
        <GalleryCard
          v-for="(entity, i) in item"
          :key="i"
          :entity="entity"
          :width="bestFitCardWidth"
          :shouldReportHeight="i == 0"
          @click.native="() => editEntity(entity.entity)"
          @height-updated="updateChildComponentHeight"
          @load-entity="loadEntity"
          class="mt-3 mb-3"
          :class="item.length < columns ? 'ml-2 mr-2' : '' "
          :showImage="statefulView.showImage()"
          :hideTypeIcon="statefulView.hideTypeIcon"
          :hideFieldName="statefulView.hideFieldName"
          :cardSize="statefulView.cardSize"
        />
        
        </div>
      </template>
    </v-virtual-scroll>
    </div>

    <FullHeightLoader v-model="loading" />
    <EditEntityDialog
      :virtualGrid="virtualGrid"
      :entity="selectedEntity"
      v-model="editEntityDialog"
    />
  </BaseStatefulView>
</template>

<script>
import BaseStatefulView from '@/views/BaseStatefulView.vue'
import FullHeightLoader from '@/components/FullHeightLoader.vue'
import EditEntityDialog from '@/components/gridView/EditEntityDialog.vue'
import VirtualGridToolbar from '@/components/gridView/VirtualGridToolbar.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'
import GalleryCard from '@/components/galleryView/GalleryCard.vue'
import GalleryCardSettings from '@/components/galleryView/GalleryCardSettings.vue'
import { GALLERY_CARD_SIZE_SMALL, GALLERY_CARD_SIZE_MEDIUM , GALLERY_CARD_SIZE_LARGE  } from '@/constants/galleryCardTypes.js'
import viewProps from '@/mixins/viewProps'
export default {
  mixins: [viewProps],
  data() {
    return {
      loading: false,
      stateLoading: false,
      selectedEntity: undefined,
      editEntityDialog: false,
      showAddEntityButton: true,
      columns: 4,
      bestFitCardWidth: 0,
      cardHeight: 0,
      windowHeight: window.innerHeight,
      cardSizeMap: null
    }
  },
  beforeDestroy() {
    this.virtualGrid.setLocalEntityParams(undefined)
  },
  mounted() {
    this.cardSizeMap = {
      [GALLERY_CARD_SIZE_SMALL]: 250,
      [GALLERY_CARD_SIZE_MEDIUM]: 350,
      [GALLERY_CARD_SIZE_LARGE]: 450
    }
  },
  watch: {
    statefulViewUri: {
      immediate: true,
      async handler() {
        this.loading = true
        try {
          const statefulView = await this.$store.dispatch(
            'AGReadStatefulViewOperation',
            this.statefulViewUri
          )
          await this.$store.dispatch('AGReadVirtualGridOperation', {
            virtualGridUri: statefulView.parentGridUri,
            loadEntities: false
          })
          statefulView.initShownFields()
          await this.$nextTick()
          this.bestFitCardWidth = this.cardSizeMap[statefulView.cardSize]
          this.virtualGrid.loadEntities()
        } finally {
          this.loading = false
        }
      }
    },
    preferredCardWidth: {
      handler() {
          this.onResize()
      }
    }
  },
  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    stateFieldId: {
      get() {
        return this.statefulView?.stateFieldId
      },
      set(newVal) {
        if (this.stateLoading) {
          return
        }
        this.stateLoading = true
        this.statefulView
          .setStateFieldId(newVal)
          .finally(() => (this.stateLoading = false))
      }
    },
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    virtualGrid() {
      return this.statefulView?.parentGrid
    },
    virtualGridUri() {
      return this.statefulView?.parentGridUri
    },
    canAddEntity() {
      return hasPermission(this.grid, [PERMISSIONS.addEntity])
    },
    canPatchView() {
      return hasPermission(this.statefulView, [PERMISSIONS.patch])
    },
    canUpdateFieldKey() {
      return hasPermission(this.virtualGrid, [PERMISSIONS.updateFieldKey])
    },
    cardsModel() {
      if (!this.virtualGrid || !this.statefulView) {
        return
      }
      return this.statefulView.cardsModel(
        this.virtualGrid, this.columns
      )
    },
    itemHeight() {
      return this.cardHeight + 16
    },
    preferredCardWidth() {
      if(!this.statefulView || !this.cardSizeMap) return 0
      return this.cardSizeMap[this.statefulView.cardSize]
    }
  },
  provide() {
    return {
      canUpdateFieldKey: () => this.canUpdateFieldKey
    }
  },
  methods: {
    reloadView() {
      this.virtualGrid.reload()
      this.statefulView.reload()
    },
    editEntity(entity) {
      this.selectedEntity = entity
      this.editEntityDialog = true
    },
    onResize () {
        // handle changes of width
        if(!this.$refs.scroller) return
        let containerWidth = this.$refs.scroller.offsetWidth
        const cardsPerRow = Math.floor(containerWidth / this.preferredCardWidth)
        const spaceAvailable = containerWidth % this.preferredCardWidth
        this.bestFitCardWidth = this.preferredCardWidth + (spaceAvailable / cardsPerRow) - 16
        this.columns = cardsPerRow
        // handle changes of height
        this.windowHeight = window.innerHeight
    },
    updateChildComponentHeight(height) {
      let newHeight = height
      if( newHeight != this.cardHeight ) {
        this.cardHeight = newHeight
      }
    },
    loadEntity(entity) {
      this.statefulView.shouldLoadEntity(entity, this.virtualGrid)
    }
  },
  components: {
    BaseStatefulView,
    FullHeightLoader,
    EditEntityDialog,
    VirtualGridToolbar,
    GalleryCard,
    GalleryCardSettings
  }
}
</script>

<style scoped>

.full-height {
  height: 100%;
  background-color: white;
}
</style>