<template>
  <div>
          <v-expansion-panels v-model="panel" multiple accordion flat>

          <FormFieldListSection :title="$t('forms.builder.saveForLater.featureHeadline')">
            <PaywallMenu :feature="$apptive.constants.features.SAVE_FORM_PROGRESS">
            <v-switch
              v-model="saveProgress"
              @change="saveForm"
              inset
              dense
              left
              >
              <template #label><span class="subtitle-2">{{$t('forms.builder.saveForLater.featureToggleLabel')}}</span> </template></v-switch>
              <div class="caption">
                {{$t('forms.builder.saveForLater.featureDescription')}}
              </div>
            </PaywallMenu>
          </FormFieldListSection>

          <FormFieldListSection :title="$t('forms.builder.autoSubmit.featureHeadline')">
            <PaywallMenu :feature="$apptive.constants.features.AUTO_SUBMIT_FORM">
            <v-switch
              v-model="autoSubmit"
              @change="saveForm"
              inset
              dense
              left
              >
              <template #label><span class="subtitle-2">{{$t('forms.builder.autoSubmit.featureToggleLabel')}}</span> </template></v-switch>
              <div class="caption">
                {{$t('forms.builder.autoSubmit.featureDescription')}}
              </div>
            </PaywallMenu>
          </FormFieldListSection>

          <FormFieldListSection :title="$t('forms.builder.hideSubmitButton.featureHeadline')">
            <v-switch
              v-model="hideSubmitButton"
              @change="saveForm"
              inset
              dense
              left
              >
              <template #label><span class="subtitle-2">{{$t('forms.builder.hideSubmitButton.featureToggleLabel')}}</span> </template></v-switch>
              <div class="caption">
                {{$t('forms.builder.hideSubmitButton.featureDescription')}}
              </div>
            </FormFieldListSection>

          <FormFieldListSection :title="$t('forms.builder.i18n.featureHeadline')">

            <PaywallMenu :feature="$apptive.constants.features.FORM_I18N">
              <FormI18nEditor v-if="i18nDialog" v-model="i18nDialog" class="mx-3" :form="form"/>
              <div v-else>
                <v-switch
                  v-model="i18nFeature"
                  @change="saveForm"
                  inset
                  dense
                  left
                >
                  <template #label>
                    <span class="subtitle-2">{{$t('forms.builder.i18n.featureToggleLabel')}}</span>
                  </template>
                </v-switch>
                <div v-show="!i18nFeature" class="caption mt-3">
                  {{$t('forms.builder.i18n.featureDescription')}}
                </div>
                <FormI18nList v-show="i18nFeature" v-model="i18nDialog" :form="form" class="mt-2"/>
              <v-btn
                v-show="i18nFeature"
                @click="i18nDialog = true"
                color="primary"
                dense text
                >
                <span class="subtitle-2">{{$t('forms.builder.i18n.buttonLabel')}}</span>
              </v-btn>
            </div>
            </PaywallMenu>
          </FormFieldListSection>
        </v-expansion-panels>
      </div>
</template>

<script>

import FormFieldListSection from '@/components/form/formbuilder/FormFieldListSection.vue'
import FormI18nEditor from '@/components/form/formbuilder/FormI18nEditor.vue'
import FormI18nList from '@/components/form/formbuilder/FormI18nList.vue'
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'

export default {
  props: {
    spaceUri: null,
    gridUri: null,
    formUri: null
  },
  data() {
    return {
      panel: [0, 1, 2],
      i18nDialog: false
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri) ?? {}
    },
    form() {
      return this.$store.getters.formWithUri(this.formUri)
    },
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    saveProgress: {
      get() {
        return this.form.saveProgress ?? false
      },
      set(value) {
        this.form.saveProgress = value
      }
    },
    autoSubmit: {
      get() {
        return this.form.autoSubmit ?? false
      },
      set(value) {
        this.form.autoSubmit = value
      }
    },
    hideSubmitButton: {
      get() {
        return this.form.hideSubmitButton ?? false
      },
      set(value) {
        this.form.hideSubmitButton = value
      }
    },
    i18nFeature: {
      get() {
        return this.form.i18nFeature ?? false
      },
      set(value) {
        this.form.i18nFeature = value
      }
    },
  },
  methods: {
    saveForm() {
      this.form.updateComponents()
    },
  },
  components: {
    FormFieldListSection,
    PaywallMenu,
    FormI18nEditor,
    FormI18nList
}
}
</script>
