<template>
<v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    :nudge-width="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-1"
        :color="buttonColor"
        v-bind="attrs"
        v-on="on"
        text
        :style="buttonBackground"
        small
        data-testid="gridGroupingMenuActivator"
      >
      <v-icon small class="mr-sm-2">mdi-format-list-group</v-icon>
      <span v-if="label" class="d-none d-sm-flex">{{ buttonText }}</span>
      </v-btn>
    </template>

    <v-card class="pa-3">
      <div class="d-flex align-center gap-4">
        <div class="text-caption">{{$t('grouping.groupBy')}}</div>
        <CompactSelect
          :showControls="showControls"
          :disabled="disabled"
          v-model="fieldId"
          class="field-select"
          :items="fieldSelectItems"
          @change="fieldNameChanged"
          :no-data-text="$t('grouping.noFieldAvailable')"
          clearable
          data-testid="gridFilterFieldSelect"
        >
          <template v-slot:label>
            <div class="text-caption">
              {{$t('virtualGrid.filter.fieldNameLabel')}}
            </div>
          </template>
        </CompactSelect>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import colors from '@/constants/colors.js'
import CompactSelect from '@/components/CompactSelect.vue'

export default {
  props: {
    statefulView: null,
    disabled: null,
    showControls: {
      type: Boolean,
      default: () => true
    },
    label: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      menu: false,
      fieldId: undefined,
      sortingCriterias: []
    }
  },
  watch: {
    'statefulView.groupByFieldId': {
      immediate: true,
      handler(newVal) {
        this.fieldId = newVal
      }
    }
  },
  computed: {
    field() {
      return this.statefulView.parentGrid.parentGrid().fields.find(field => field.id === this.fieldId)
    },
    fieldSelectItems() {
      if (!this.statefulView) {
        return []
      }
      // Grouping relies on sorting by the field
      return this.statefulView.parentGrid.fields
        .filter(field => !field.columnType.disableSorting)
        .map(field => {
          return {
            value: field.id,
            text: field.name
          }
        })
    },
    isActive() {
      return this.statefulView?.isGrouped()
    },
    buttonColor() {
      return this.isActive ? 'primary' : 'accent'
    },
    buttonBackground() {
      return {
        background: this.isActive ? colors.grouping : undefined
      }
    },
    buttonText() {
      return this.$t('grouping.menuButtonLabel')
    }
  },
  methods: {
    fieldNameChanged() {
      this.statefulView.setGroupBy(this.fieldId)
    }
  },
  components: {
    CompactSelect
  }
}
</script>

<style scoped>
.field-select {
  max-width: 210px;
}
</style>