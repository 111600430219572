export default {
  computed: {
    welcomeMessage() {
      const hour = new Date().getHours()
      let timeGreeting
      let name = this.user ? this.user.firstName : ''
      
      if (hour < 10) {
        timeGreeting = this.$t('spaces.greetings.early_morning', { name })
      } else if (hour < 12) {
        timeGreeting = this.$t('spaces.greetings.late_morning', { name })
      } else if (hour < 17) {
        timeGreeting = this.$t('spaces.greetings.afternoon', { name })
      } else {
        timeGreeting = this.$t('spaces.greetings.evening', { name })
      }
      
      return timeGreeting
    }
  }
} 