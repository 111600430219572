export default {
  props: {
    spaceUri: {
      type: String,
      default: null
    },
    gridUri: {
      type: String,
      default: null
    },
    statefulViewUri: {
      type: String, 
      default: null
    },
    isStandalone: {
      type: Boolean,
      default: false
    },
    embedded: {
      type: Boolean,
      default: false
    },
    autoreload: {
      type: Boolean,
      default: false
    },
    showToolbar: {
      type: Boolean,
      default: true
    },
    toolbarLabels: {
      type: Boolean,
      default: true
    }
  }
} 