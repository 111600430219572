<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    color="accent"
    text small
  >
    <v-icon
      small
      :left="!$vuetify.breakpoint.mobile && label"
    >{{icon}}</v-icon>
    <span
      v-if="!$vuetify.breakpoint.mobile && label"
    >{{text}}</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: String,
    text: String,
    label: {
      type: Boolean,
      default: true
    }
  }
}
</script>
