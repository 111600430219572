<template>
  <v-sheet class="d-flex flex-column step-inspector">
    <v-toolbar flat class="pl-6" dense>
      <v-toolbar-title class="font-weight-medium">{{title}}</v-toolbar-title>
      <v-spacer/>
      <v-btn :disabled="!canRetry" icon @click="retryStep" :loading="retrying">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
    <FlowInstanceDetails :flow="flow" :step="step" noTitle/>
  </v-sheet>
</template>

<script>
import FlowInstanceDetails from './FlowInstanceDetails.vue'
import externalModel from '../../mixins/externalModel'
import { instanceStates } from '@/store/models/flow/FlowInstance.js'

export default {
  mixins: [externalModel],
  props: {
    node: null,
    flow: null,
    positionX: null,
    positionY: null,
  },
  data() {
    return {
      outputGroup: true,
      retrying: false
    }
  },
  computed: {
    title() {
      return this.node?.name
    },
    step() {
      return this.flow?.testInstance?.steps.find( step => step?.getLink('node') == this.node?.uri)
    },
    canRetry() {
      return this.step?.state === instanceStates.error
    }
  },
  methods: {
    async retryStep() {
      if (!this.step) return
      this.retrying = true
      try {
        await this.$store.dispatch('AGRetryFlowInstanceOperation', this.step)
        const response = await this.$store.dispatch('AGReadFlowInstanceOperation', this.flow.testInstance)
        this.flow.testInstance = response
      } finally {
        this.retrying = false
      }
    }
  },
  components: {
    FlowInstanceDetails
  }
}
</script>

<style lang="css" scoped>
.step-inspector {
  max-height: 100%;
}
</style>
