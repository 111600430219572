import FormInputBlock from '@/store/models/blocks/FormInputBlock'
import store from '@/store/store'

export default class VideoBlock extends FormInputBlock {
  constructor(data) {
    super(data)
    this.videoInput = data.videoInput
    this.isDirty = false
    this.hasVideoEnded = false
  }

  validate() {
    return this.required ? this.hasVideoEnded : true
  }

  get video() {
    return this.videoInput.video
  }

  setVideo(newVal) {
    if (newVal === this.videoInput.video) {
      return
    }
    this.videoInput.video = newVal
    this.isDirty = true
  }

  async updateValue() {
    if (!this.isDirty) {
      return
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        videoInput: {
          video: this.video
        }
      }
    })
    this.isDirty = false
  }
}