let webVersion

// Function to load the version from the web
async function loadWebVersion() {
  try {
    const response = await fetch('/version.txt')
    if (!response.ok) {
      throw new Error(`Failed to fetch version: ${response.statusText}`)
    }
    webVersion = await response.text()
    return webVersion
  } catch (error) {
    console.log(error)
  }
}

export { loadWebVersion, webVersion }