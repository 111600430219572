import Block from '@/store/models/blocks/Block'
import store from '@/store/store'

export default class ResourceBlock extends Block {
  constructor(data) {
    super(data)
  }

  get resourceUri() {
    return this.resourceBlock?.resource?._links.self.href
  }

  set resourceUri(newVal) {
    if (newVal === this.resourceUri) {
      return
    }
    this.resource = newVal
    this.isDirty = true
  }

  get height() {
    return this.resourceBlock.height
  }

  setHeight(newVal) {
    if (newVal === this.resourceBlock.height) {
      return
    }
    this.resourceBlock.height = newVal
    this.isDirty = true
  }

  get width() {
    return this.resourceBlock.width
  }

  setWidth(newVal) {
    if (newVal === this.resourceBlock.width) {
      return
    }
    this.resourceBlock.width = newVal || null
    this.isDirty = true
  }

  async updateValue() {
    if (!this.isDirty) {
      return
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        resourceBlock: {
          resource: this.resource?.unwrap() || { href: this.resourceBlock.resource._links.self.href },
          height: this.height,
          width: this.width
        }
      }
    })
    await this.reload()
    this.isDirty = false
  }
}
