import { blockTypes } from '@/constants/blockTypes'

function blockTypeFromData(blockData) {
  // Find all block types that match the type in blockData
  const matchingBlockTypes = Object.values(blockTypes).filter(item => item.type === blockData.type)
  if (matchingBlockTypes.length === 0) {
    console.error('Unrecognized block data type:', blockData.type)
    return null // Return null if no matching block type found
  }

  // If there's only one matching block type, return it
  if (matchingBlockTypes.length === 1) {
    return matchingBlockTypes[0]
  }

  // If there are multiple matching block types, use the match function
  const matchedBlockType = matchingBlockTypes.find(blockType => blockType.match && blockType.match(blockData))

  if (matchedBlockType) {
    return matchedBlockType// Return the matched block type
  }

  console.error('No matching block type found for block data:', blockData)
  return null // Return null if no matching block type found
}

export function createBlock(blockData) {
  const blockType = blockTypeFromData(blockData)
  if (blockType == null) {
    console.error('Unrecognized block data')
    console.error(JSON.stringify(blockData, null, 2))
  }
  const block = new blockType.modelClass(blockData)
  block.blockType = blockType
  block.children = blockData.children ? blockData.children.map(child => createBlock(child)) : []
  return block
}
