<template>
  <div
    class="d-flex flex-column justify-space-between flex-grow list"
    data-tutorial="grid-views-list"
    data-testid="virtualGridList"
  >
    <div class="d-flex align-center">
      <v-progress-circular v-show="loading" class="ml-auto mr-3" indeterminate color="primary" width='1' size="16" />
    </div>
    <AGDraggable
      tag="transition-group"
      :componentData="{props: {name: 'fade'}}"
      @end="onStatefulViewItemDragEnd"
    >
    <div v-for="(item, index) in statefulViewItems" :key="item.name + item.uri" class="d-flex flex-column flex-grow-1">
      <v-hover v-slot="{ hover }">
        <router-link :data-testid="`${item.testid}-${index}`" :to="item.to" class="text-decoration-none" > 
        <div :data-testid="item.testid" class="d-flex align-center justify-center py-2 px-2" :class="{ 'on-hover': hover, 'selected': isSelected(item) }">
          <ViewIcon :size="18" :type="item.iconType" class="mr-1" />

          <div class=" flex-grow-1 pr-1 subtitle-2 black--text block-name-container">

            <EditableText
              :readonly="!item.canUpdate"
              :text="item.name"
              @submit="newName => item.rename(newName)"
            />
          </div>
          <StatefulViewMenu
            v-show="hover && item.canUpdate"
            :statefulViewUri="item.to.params.statefulViewUri"
            class="ml-2"
            style="line-height: 1.0 !important;"
          />
        </div>
        </router-link>
      </v-hover>
    </div>
  </AGDraggable>


   <!-- FormItems -->
   <div v-for="(item) in formItems" :key="item.name + item.uri" class="d-flex flex-column flex-grow-1 ">
      <PaywallMenu :feature="$apptive.constants.features.FORMS_PER_SPACE" :count="formItems.indexOf(item)">
        <v-hover v-slot="{ hover }">
           <router-link :to="item.to" class="text-decoration-none">
          <div :data-testid="item.testid" class="d-flex align-center py-2 px-2" :class="{ 'on-hover': hover, 'selected': isSelected(item) }">
            <ViewIcon :size="18" :type="item.iconType" class="mr-1" />
            <div  class=" flex-grow-1 subtitle-2 pr-1 text-decoration-none black--text block-name-container">
              <EditableText
                :readonly="!item.rename"
                :text="item.name"
                @submit="newName => item.rename(newName)"
              />
            </div>
            <FormMoreMenu
              v-show="hover"
              :formUri="item.to.params.formUri"
              class="ml-2"
              style="line-height: 1.0 !important;"
              v-on:didDeleteForm="didDeleteForm"
            />
          </div>
          </router-link>
        </v-hover>
      </PaywallMenu>
    </div>

</div>

</template>

<script>
import {ViewIconTypes} from '@/constants/viewIconTypes.js'
import ViewIcon from '@/components/ViewIcon.vue'
import EditableText from '@/components/EditableText.vue'
import StatefulViewMenu from '@/components/space/StatefulViewMenu.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import FormMoreMenu from '@/components/form/FormMoreMenu.vue'
import AGDraggable from '../AGDraggable.vue'
import PaywallMenu from '../paywall/PaywallMenu.vue'

export default {
  name: 'GridNavigation',
  props: {
    space: Object,
    gridUri: String,
    width: Number,
    loading: Boolean,
    active: Boolean,
  },
  data() {
    return {
      createMenuOpen: false,
      loadingViewIconType: false,
      loadingForm: false,
    }
  },
  created() {
    this.ViewIconTypes = ViewIconTypes
  },
  watch: {
    statefulViewItems: {
      immediate: true,
      handler(items) {
        if ( this.$route.name === 'Grid' && items && items.length > 0 && !this.$route.params.statefulViewUri && !this.$route.params.formUri && this.$route.params.gridUri === this.gridUri) {
          // console.log('statefulViewItems navigating to', items[0].to)
          this.$router.push(items[0].to)
        } 
      }
    },
    '$route.name': {
      immediate: true,
      handler(name) {
      if (name === 'Grid' && this.isSelectedGrid && this.navItems.length > 0) {
            // console.log('$route.name navigating to', this.navItems[0].to)
            this.$router.replace(this.navItems[0].to)
        }
      }
    }
  },
  computed: {
    statefulViewItems () {
      const navItems = {
        spreadsheet: statefulView => ({
          name: statefulView.name,
          uri: statefulView.uri,
          iconType: ViewIconTypes.GRID,
          testid: 'virtualGridListItem',
          to: {name: 'VirtualGrid', params: { gridUri: this.gridUri, statefulViewUri: statefulView.uri }},
        }),
        kanban: statefulView => ({
          name: statefulView.name,
          uri: statefulView.uri,
          iconType: ViewIconTypes.KANBAN,
          testid: 'kanbanListItem',
          to: {name: 'KanbanView', params: { gridUri: this.gridUri, statefulViewUri: statefulView.uri }}
        }),
        calendar: statefulView => ({
          name: statefulView.name,
          uri: statefulView.uri,
          iconType: ViewIconTypes.CALENDAR,
          testid: 'calendarListItem',
          to: {name: 'CalendarView', params: { gridUri: this.gridUri, statefulViewUri: statefulView.uri }}
        }),
        map: statefulView => ({
          name: statefulView.name,
          uri: statefulView.uri,
          iconType: ViewIconTypes.MAP,
          testid: 'mapListItem',
          to: {name: 'MapView', params: { gridUri: this.gridUri, statefulViewUri: statefulView.uri }}
        }),
        gallery: statefulView => ({
          name: statefulView.name,
          uri: statefulView.uri,
          iconType: ViewIconTypes.GALLERY,
          testid: 'galleryListItem',
          to: {name: 'GalleryView', params: { gridUri: this.gridUri, statefulViewUri: statefulView.uri }}
        }),
      }

      return this.statefulViews
        .map(statefulView => {
          const navItem = navItems[statefulView.type]?.(statefulView)
          const fullView = this.$store.getters.statefulViewWithUri(statefulView.uri)
          navItem.canUpdate = fullView?.canUpdate()
          if (navItem.canUpdate) {
            navItem.rename = newName => {
              fullView.rename(newName)
            }
          }
          else {
            navItem.rename = () => {}
          }
          navItem.parentGridUri = statefulView._links.grid.href
          return navItem
        })
        .filter(item => item != null)
    },
    formItems() {
      return this.forms.map(form => {
        const fullForm = this.$store.getters.formWithUri(form.uri)
        let navItem = {
          name: form.name,
          uri: form.uri,
          iconType: ViewIconTypes.FORM,
          testid: 'virtualGridFormListItem',
          to: {name: 'FormBuilder', params: { gridUri: this.gridUri, formUri: form.uri}}
        }
        if (fullForm && hasPermission(fullForm, [PERMISSIONS.update])) {
          navItem.rename = newName => this.renameForm(fullForm, newName)
        }
        else {
          navItem.rename = () => {}
        }
        return navItem
      })
    },
    navItems() {
      return [...this.statefulViewItems, ...this.formItems]
    },
    forms() {
      if (!this.grid) {
        return []
      }
      return this.$store.getters.formsOfGrid(this.grid.uri)
    },
    statefulViews() {
      if (!this.grid) {
        return []
      }
      return this.$store.getters.statefulViewsOfGrid(this.grid.uri)
    },
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    isSelectedGrid() {
      return this.$route.params.gridUri === this.gridUri
    },
    canAddView() {
      return hasPermission(this.grid, [PERMISSIONS.addView])
    },
    canAddForm() {
      return hasPermission(this.grid, [PERMISSIONS.addForm])
    },
  },
  methods: {
    isSelected(item) {
      return this.$route.params.statefulViewUri === item.uri || this.$route.params.formUri === item.uri
    },
    onStatefulViewItemDragEnd(event) {
      const statefulViewItem = this.statefulViewItems[event.oldIndex]
      this.$store.dispatch('AGMoveStatefulViewOperation', {
        space: this.space,
        virtualGridUri: statefulViewItem.parentGridUri,
        position: event.newIndex
      })
    },
    didDeleteForm() {
      this.$router.back()
    },
    renameForm(form, newName) {
      if (form.name == newName) {
        return
      }
      return form.rename(newName)
    }
  },
  components: {
    ViewIcon,
    EditableText,
    StatefulViewMenu,
    FormMoreMenu,
    AGDraggable,
    PaywallMenu,
}
}
</script>

<style lang="css" scoped>
.list {
    position: relative;
}
.block-name-container {
  overflow: hidden;
  text-overflow: ellipsis;
}


.on-hover {
  background-color: #ededed;
  border-radius: 12px;
}
.selected {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.09);
}
.fade-enter-active {
  transition-delay: .1s;
  transition-duration: .3s;
}
.fade-leave-active {
  transition-duration: .1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.overflow-auto {
  overflow: auto;
}

</style>