import mocks from './mocks.js'
import Axios from 'axios'
import errorBus from '@/utils/errorBus.js'
import * as auth from '@/utils/auth.js'
import { btoaUtf8Hack } from '@/utils/jsUtils'
import linkAuthorizationNeededBus from '@/utils/linkAuthorizationNeededBus.js'

var BASE_URL = ''

var mock = false
export const axios = Axios.create()
setErrorHandling()

var onTokenChangedHandler = null

// Create a list to hold the request queue
const refreshAndRetryQueue = []

// Flag to prevent multiple token refresh requests
let isRefreshing = false

function client() {
  return mock ? MockApiClient : ApiClient
}

function setErrorHandling() {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      // in case of urls that are protected via basic auth like view links
      // we show a login terminal an retry the request

      if (error.response.status === 401 && error.response.headers['apptive-authorization'] == 'link')
      {
        linkAuthorizationNeededBus.requestAuthorization()
      }
      // for all other 401 we refresh the token and retry
      else if (error.response.status === 401 && !originalRequest._retry) {
         // Mark the request to indicate it's being retried
          originalRequest._retry = true
        if (!isRefreshing) {
          isRefreshing = true
          try {
            // Refresh the access token
            let succesful = await auth.authenticate()
            if(!succesful) {
              // No token could be generated, the user needs to login
              auth.logout()
              return
            }
            // Update the request headers with the new access token
            originalRequest.headers['Authorization'] = axios.defaults.headers.common['Authorization']

            // Retry all requests in the queue with the new token
            refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
              axios
                .request(config)
                .then((response) => resolve(response))
                .catch((err) => reject(err))
            })

            // Clear the queue
            refreshAndRetryQueue.length = 0
            // Retry the original request
            return axios(originalRequest)
          } catch (refreshError) {
            // Handle token refresh error
            // You can clear all storage and redirect the user to the login page
            auth.logout()
            auth.toLoginPage()
            throw refreshError
          } finally {
            isRefreshing = false
          }
        }
        // Add the original request to the queue
        return new Promise((resolve, reject) => {
          refreshAndRetryQueue.push({ config: originalRequest, resolve, reject })
        })
      }
      else {

        if (error.response?.data != null) {
          var errorJSON = JSON.stringify(error.response.data)
          error.message = error.message + errorJSON.substr(1, 200)
          error.type = error.response.data.error
        }

        // AC-1411 : Do not show the generic error popup if the request will receive a
        // custom error handling. if the status code is part of customeErrorHandling, the caller need to
        // call errorBus.$emit('ERROR') to trigger the popup. 
        const customErrorHandling = error.response.config?.customErrorHandling ?? []
        if (error.response.status !== 401 && !customErrorHandling.includes(error.response.status)) {
          errorBus.$emit('ERROR', error)
        }
      }
      return Promise.reject(error) // this is the important part
    }
  )
}

const ApiClient = {
  setAuth: function(token) {
    if (token == null) {
      axios.defaults.headers.common['Authorization'] = ''
    } else {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
    if(onTokenChangedHandler){
      onTokenChangedHandler(token)
    }
  },
  setBasicAuth(username, password) {
    if (username == null) {
      axios.defaults.headers.common['Authorization'] = ''
    } else {
      axios.defaults.headers.common['Authorization'] = `Basic ${btoa(btoaUtf8Hack(username) + ':' + btoaUtf8Hack(password))}`
    }
  },
  bearerAuthSet(){
    const auth = axios.defaults.headers.common['Authorization'] 
    return auth && auth.includes('Bearer')
  },
  onTokenChanged: function(handler) {
    onTokenChangedHandler = handler
  },
  getUri: function(uri, params, config) {
    var url = `${BASE_URL}${uri}`
    return axios.get(url, {
      params: params,
      ...config
    })
  },
  postUri: function(uri, data, params) {
    var url = `${BASE_URL}${uri}`
    return axios({
      method: 'post',
      url: url,
      data: data,
      params
    })
  },
  putUri: function(uri, data, params) {
    var url = `${BASE_URL}${uri}`
    return axios({
      method: 'put',
      url: url,
      data: data,
      params
    })
  },
  patchUri: function(uri, data, params) {
    var url = `${BASE_URL}${uri}`
    return axios({
      method: 'patch',
      url: url,
      data: data,
      params
    })
  },
  deleteUri: function(uri) {
    var url = `${BASE_URL}${uri}`
    return axios.delete(url)
  }
}

const MockApiClient = {
  setUser: function(aUser) {
    console.log(aUser)
  },
  getUri: function(uri) {
    console.log('loading ' + uri)
    console.log(
      'return ' +
        JSON.stringify({
          data: mocks[uri]
        })
    )
    return Promise.resolve({
      data: mocks[uri]
    })
  },
  postUri: function(uri, data) {
    console.log('loading ' + uri)
    console.log('sending ' + JSON.stringify(data))
    console.log(
      'return ' +
        JSON.stringify({
          data: mocks[uri]
        })
    )
    return Promise.resolve({
      data: mocks[uri]
    })
  },
  putUri: function(uri, data) {
    console.log('loading ' + uri)
    console.log('sending ' + JSON.stringify(data))
    console.log(
      'return ' +
        JSON.stringify({
          data: mocks[uri]
        })
    )
    return Promise.resolve({
      data: mocks[uri]
    })
  },
  onError: function() {}
}

export default client()
