<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode">
    <template v-slot:menu>
      <v-menu v-if="stateComponent && editorMode" v-model="settingsMenu" :close-on-content-click="false">
        <template v-slot:activator="{on}">
          <MenuItem
            icon="mdi-cog-outline"
            :text="$t('blocks.inputBlock.blockSettings')"
            v-on="on"
          />
        </template>
        <v-sheet class="pa-3">
          <component
            class="background"
            :is="stateComponent"
            :space="space"
            v-model="statefulTypeModel"
          />
          <div class="d-flex justify-end">
            <v-btn text color="primary" @click="submitType">{{$t('dialogs.okButton')}}</v-btn>
          </div>
        </v-sheet>
      </v-menu>
      <MenuItem
        icon="mdi-asterisk"
        :text="$t('blocks.inputBlock.required')"
        showSwitch
        v-model="isRequired"
      />
    </template>
    <template v-slot:default="{showControls}">
      <BaseInputBlock :blockUri="blockUri" :editorMode="editorMode" :showControls="showControls">
        <div>
          <CellAsBlock :blockUri="blockUri" v-model="inputValue" @blur="validateAndUpdate"/>
          <div class="error-container">
            <transition name="fade">
              <span
                v-if="showError"
                class="mt-2 red--text subtitle-2 error-message"
                >
                {{ $t('blocks.inputBlock.requiredFieldError') }}
              </span>
            </transition>
          </div>
        </div>
      </BaseInputBlock>
    </template>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import BaseInputBlock from '@/components/block/BaseInputBlock.vue'
import FormFieldStyles from '@/constants/formFieldStyles.js'
import CellAsBlock from './CellAsBlock.vue'
import MenuItem from '@/components/MenuItem.vue'

export default {
  props: {
    blockUri: {
      type: String,
      default: () => null
    },
    editorMode: {
      type: Boolean,
      default: () => false
    }
  },
  inject: ['spaceUri'],
  data() {
    return {
      settingsMenu: false,
      statefulTypeModel: undefined,
      showError: false
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    fieldStyle() {
      return FormFieldStyles.outlined
    },
    inputValue: {
      get() {
        return this.block.inputValue
      },
      set(value) {
        this.block.inputValue = value
      }
    },
    isRequired: {
      get() {
        return this.block.required
      },
      set(value) {
        this.block.setRequired(value)
      }
    },
    stateComponent() {
      return this.block.columnType?.state?.component
    }
  },
  watch: {
    block: {
      immediate: true,
      handler(newVal) {
        if (newVal == null) {
          this.statefulTypeModel = undefined
        } else {
          this.statefulTypeModel = this.block.columnType?.state?.initialisation(this.block.valueType)
        }
      }
    }
  },
  methods: {
    validateAndUpdate() {
      if (!this.block.validate()) {
        this.showError = true
        this.$emit('validationError', true)
      } else {
        this.showError = false
        this.$emit('validationError', false)
        this.updateBlock()
      }
    },
    updateBlock() {
      this.$emit('updateBlock')
    },
    submitType() {
      this.block.patchType(this.statefulTypeModel)
      this.settingsMenu = false
    }
  },
  components: {
    CellAsBlock,
    BaseBlock,
    BaseInputBlock,
    MenuItem
  }
}
</script>

<style lang="css" scoped>
.outlined>>>.v-input__slot {
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
}

.error-container {
  position: relative;
  height: 24px; /* Adjust this value based on your error message height */
}

.error-message {
  position: absolute;
  top: 0;
  left: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>