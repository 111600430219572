<template>
  <div :style="{height: '100%'}">
    <v-app-bar  v-if="!embedded" extension-height="32px" height="48px" color="white" app flat clipped-right>
      <v-toolbar-title class="font-weight-medium">{{title}}</v-toolbar-title>
      <v-spacer/>
      <v-btn text v-if="canLogout" @click="logout" class="font-weight-bold" >Logout<v-icon class="ml-1">mdi-logout</v-icon></v-btn>

      <a v-else href="https://www.apptivegrid.de?utm_source=app-apptivegrid&utm_medium=shared-link&utm_campaign=apptive-shared-view" target="_blank" >
        <img class="d-flex self-align-center" width="130px" :src="logo" />
      </a>

    </v-app-bar>
    <div v-if="!loading && !statefulView" class="error-display d-flex justify-center align-center text-h4">{{errorMessage}}</div>
    <component v-else :is="viewComponent" :isStandalone="true" :embedded="embedded" :autoreload="true"  :statefulViewUri="resolvedStatefulViewUri" :showToolbar="false" />
    <LoadingOverlay :show="loading" />
    <v-btn
      v-if="redirectUri"
      @click="formDialog = true"
      color="primary"
      fab fixed bottom right
    >
      <v-icon size="42">mdi-plus</v-icon>
    </v-btn>
    <v-dialog
      v-model="formDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card height="100%">            
      <v-btn
            color="white"
            fab fixed top right
            @click="formDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
            <iframe v-if="formDialog" :src="redirectUri" frameborder="0" ref="iframe" onmousewheel="" width="100%" height="100%" ></iframe>
      </v-card>
  </v-dialog>
  <LoginDialog @credentialsProvided="credentialsProvided" v-model="loginDialog" :resource="loginResource" />
  </div>

</template>

<script>
import VirtualGrid from '@/components/gridView/GridView.vue'
import KanbanView from '@/components/kanbanView/KanbanView.vue'
import CalendarView from '@/components/calendar/CalendarView.vue'
import MapView from '@/components/mapView/MapView.vue'
import GalleryView from '@/components/galleryView/GalleryView.vue'
import ExternalForm from '@/views/ExternalForm.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import LoginDialog from '@/components/LoginDialog.vue'
import linkAuthorizationNeededBus from '@/utils/linkAuthorizationNeededBus'

export default {
  props: {
    statefulViewUri: null,
  },
  data() {
    return {
      statefulView: undefined,
      loading: false,
      formDialog: false,
      loginDialog: false,
      uri: undefined,
      errorMessage: undefined,
      canLogout: false
    }
  },
  provide() {
    return {
      plan: () => {}
    }
  },
  watch: {
    statefulViewUri: {
      immediate: true,
      async handler(newVal) {
        linkAuthorizationNeededBus.onLinkAuthorizationNeeded(this.onLinkAuthorizationNeeded)
        if(newVal){
          this.uri = newVal
        } else {
          // here we get the uri from the search params as vue router ignores queries that are before the /#view/
          const urlParams = new URLSearchParams(window.location.search)
          this.uri = { uri: urlParams.get('uri') }
        }
        this.loadView()
      }
    },
  },
  beforeDestroy() {
    linkAuthorizationNeededBus.linkAuthorizationNeededOff(this.onLinkAuthorizationNeeded)
  },
  methods: {
    onLinkAuthorizationNeeded() {
      this.loginDialog = true
    },
    credentialsProvided() {
      this.canLogout = true
      this.loadView()
    },
    async loadView() {
        this.loading = true
        try {
          this.statefulView = await this.$store.dispatch('AGReadStatefulViewOperation', this.uri , { customErrorHandling: [404]})
        } catch (error) { 
          if (error.response.status === 404) {
            this.errorMessage = this.$t('errorMessages.unaccessibleView')
          }
        }
        finally {
          this.loading = false
        }
    },
    logout() {
      //reload view to rest auth setting in api client
      window.location.reload()
    }
  },  
  computed: {
    viewComponent() {
      const components = {
        kanban: KanbanView,
        spreadsheet: VirtualGrid,
        calendar: CalendarView,
        map: MapView,
        gallery: GalleryView,
      }
      return components[this.statefulView?.type]
    },
    title() {
      return this.statefulView?.name
    },
    redirectUri() {
      return this.statefulView?.formLink?.redirectUri
    },
    logo() {
      return `${this.$settings.app}/logo.svg`
    },
    embedded() {
      const urlParams = new URLSearchParams(window.location.search)
      const embedded = urlParams.get('embedded')
      return embedded == 'true'
    },
    loginResource() {
      return this.uri?.uri
    },
    resolvedStatefulViewUri() {
      return this.statefulView?.uri
    }
  },
  components: {
    LoadingOverlay,
    ExternalForm,
    LoginDialog
  }
}
</script>

<style>
.error-display {
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>