<template>
  <v-dialog
    v-model="externalModel"
    fullscreen
    hide-overlay
    transition="slide-x-reverse-transition"
  >
    <v-card class="landing-page">
      <v-btn
        icon
        text
        class="close-button ma-4"
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-container class="fill-height">
        <v-row align="center" justify="center" class="pa-1">
          <v-col cols="12" sm="8" md="6" class="text-center">
            <v-img
              src="/logo.png"
              contain
              height="120"
              class="mb-8"
            ></v-img>

            <h1 class="text-h4 font-weight-bold white--text mb-6">
              {{ $t('dialogs.apptiveTeams.title') }} 
            </h1>

            <p class="text-h7 white--text mb-8">
              {{ $t('dialogs.apptiveTeams.text') }}
            </p>

            <p class="text-subtitle-1 white--text mb-6">
              {{ $t('dialogs.apptiveTeams.downloadText') }}
            </p>

            <div class="d-flex flex-wrap justify-center align-center">
              <a 
                href="https://apps.apple.com/de/app/apptive-teams-mitarbeiter-app/id6477152304" 
                target="_blank"
                class="mx-2 mb-4"
              >
                <img 
                  :src="$t('virtualGrid.qrCode.dialog.appStoreBadgeSrc')" 
                  height="40"
                  alt="Download on App Store"
                />
              </a>
              <a 
                href="https://play.google.com/store/apps/details?id=de.apptivegrid.apptiveteams" 
                target="_blank"
                class="mx-2 mb-4"
              >
                <img 
                  :src="$t('virtualGrid.qrCode.dialog.playStoreBadgeSrc')" 
                  height="40"
                  alt="Get it on Google Play"
                />
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  methods: {
    close() {
      this.externalModel = false
      this.$router.push({ name: 'Spaces' })
    }
  }
}
</script>

<style scoped>
.landing-page {
  background: linear-gradient(180deg, #6213ff 0%, #b41dff 100%);
  position: relative;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.v-img {
  max-width: 200px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

a img {
  transition: transform 0.2s ease;
}

a:hover img {
  transform: scale(1.05);
}
</style> 