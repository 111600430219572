<template>
  <div style="height: 100%">

    <v-toolbar
      flat
      v-if="showToolbar"
      dense
      :style='`width: 100%;`'
      class="toolbar"
      :class="{'embedded': embedded}"
      data-tutorial="grid-toolbar">
      
      <div class="d-flex flex-grow-1 align-center justify-space-between toolbar-menu">
        <slot name="toolbar-left" />
        <v-spacer/>
        <slot name="toolbar-center" />
        <v-spacer/>
        <slot name="toolbar-right" />
      </div>

    </v-toolbar>

    <div :style="containerStyle" 
         :class="{'toolbar-padding': showToolbar && !embedded}">
      <slot />
    </div>

    <UpdateAlert v-if="canGetUpdates" :autoreload="autoreload" :gridUri="grid.uri" @reloadView="reloadView"/>
  </div>
</template>

<script>
import UpdateAlert from '@/components/UpdateAlert.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'

export default {
  props: {
    persistentGridUri: null,
    virtualGridUri: null,
    formUri: null,
    viewTitle: null,
    showToolbar: { // this is used to determine if the toolbar should be shown
        default: true
    },
    embedded: { // this us used to determine if the toolbar should be absolute or fixed
        default: false 
    },
    autoreload: { // this is used to determine if the update alert should be shown
        default: false
    }
  },
  data() {
    return {
      drawerWidth: 240,
    }
  },
  computed: {
    containerStyle() {
      return this.showToolbar && this.embedded ? { height: 'calc(100% - 48px)' } : { height: '100%' }
    },
    persistentGrid() {
      return this.$store.getters.gridWithUri(this.persistentGridUri)
    },
    grid() {
      // Prioritising virtual grid if available, this could eventually go away if forms
      // are changed to a stateful view one day.
      if (this.virtualGridUri != null) {
        return this.$store.getters.virtualGridWithUri(this.virtualGridUri)
      } else {
        return this.$store.getters.gridWithUri(this.persistentGridUri)
      }
    },
    canGetUpdates() {
      return hasPermission(this.grid, [PERMISSIONS.updates])
    },
    canRemoveField() {
      return hasPermission(this.grid, [PERMISSIONS.removeField])
    },
    canUpdateFieldName() {
      return hasPermission(this.grid, [PERMISSIONS.updateFieldName])
    },
    canUpdateFieldType() {
      return hasPermission(this.grid, [PERMISSIONS.updateFieldType])
    }
  },
  provide() {
    return {
      canRemoveField: () => this.canRemoveField,
      canUpdateFieldName: () => this.canUpdateFieldName,
      canUpdateFieldType: () => this.canUpdateFieldType
    }
  },
  methods: {
    reloadView() {
      this.$emit('reloadView')
    },
    toggleDrawer() {
      this.$store.commit('setNavigationDrawer', !this.showDrawer)
      this.$nextTick(
        () => this.$emit('toggledDrawer')
      )
    }
  },
  components: {
    UpdateAlert
  }
}
</script>

<style lang="css" scoped>
.toolbar {
  z-index: var(--grid-toolbar-zindex);
  border-style: none none none none;
  position: relative;
  background-color: white;
}
.toolbar:not(.embedded) {
  position: fixed;
}

.form-list {
  padding-left: 3rem;
}
.v-list-item.selected {
  font-weight: bold;
}
.v-list-item.selected .v-icon {
  color: inherit;
}

.toolbar-padding {
  padding-top: 48px;
}
.view-title {
  max-width: 110px;
  text-overflow: ellipsis;
  letter-spacing: normal;
}

.toolbar-menu {
  overflow-x: auto;
  overflow-y: hidden;
}

</style>
