<template>
  <div data-testid="resourceInput">
    <v-select
      v-model="selectModel"
      :items="items"
      item-value="_links.self.href"
      :item-text="displayValue"
      :loading="loading"
      hide-details solo dense
    >
      <template v-slot:item="{item}">
        <v-list-item-title class="d-flex align-center gap-3">
          <ViewIcon :type="viewIconType(item)" />
          <span :data-testid="`resourceInput-item-${items.indexOf(item)}`">{{displayValue(item)}}</span>
        </v-list-item-title>
      </template>
      <template v-slot:selection="{item}">
          <v-list-item-title class="d-flex align-center gap-3">
            <ViewIcon :type="viewIconType(item)" />
            <span>{{displayValue(item)}}</span>
          </v-list-item-title>
        </template>
    </v-select>
  </div>
</template>

<script>
import { createHolder } from '@/apptivescript/model'
import ViewIcon from '@/components/ViewIcon.vue'
import { columnTypes } from '@/constants/columnTypes'
import { ViewIconTypes } from '@/constants/viewIconTypes'
import { nodeTypeFor } from '@/constants/nodeTypes'

import externalModel from '@/mixins/externalModel'
import ApiClient from '@/ApiClient'

  export default {
    mixins: [externalModel],
    props: {
      block: null,
      loadItems: {
        type: Function,
        async default() {
          if (!this.block || !this.block._links.resources.href) return []
          try {
            const response = await ApiClient.getUri(this.block._links.resources.href)
            return response.data.items
          } catch (error) {
            console.error('Error loading items:', error)
            return []
          }
        }
      },
      onItemSelected: {
        type: Function,
        default(newVal) {
          this.externalModel = createHolder({ href: createHolder(newVal) })
          this.$emit('blur')
        }
      }
    },
    data() {
      return {
        loading: false,
        items: []
      }
    },
    computed: {
      selectModel: {
        get() {
          if (!this.externalModel || !this.items?.length) return null

          const modelHref = 
            this.externalModel?._links?.self?.href?.value 
            || this.externalModel?._links?.self?.href
            || this.externalModel?.href?.value
          return this.items.find(item =>
            item._links?.self.href === modelHref
          ) || null
        },
        set(newVal) {
          this.onItemSelected(newVal)
        }
      }
    },
    watch: {
      async block(newBlock) {
        if (!newBlock || !newBlock._links.resources.href) return
        await this.reloadItems()
      },
      loadItems: {
        immediate: true,
        async handler() {
          await this.reloadItems()
        }
      }
    },
    methods: {
      async reloadItems() {
        if (!this.loadItems) return
        this.loading = true
        try {
          this.items = await this.loadItems()
        } catch (error) {
          console.error('Error reloading items:', error)
        } finally {
          this.loading = false
        }
      },
      viewIconType(resource) {
        const iconMap = {
          // Grid view types
          spreadsheet: ViewIconTypes.GRID,
          kanban: ViewIconTypes.KANBAN,
          calendar: ViewIconTypes.CALENDAR,
          map: ViewIconTypes.MAP,
          gallery: ViewIconTypes.GALLERY,
          
          // Other resource types
          form: ViewIconTypes.FORM,
          externalFlowTrigger: ViewIconTypes.FLOW_FORM,
          addEntity: ViewIconTypes.FORM,
          view: ViewIconTypes.GRID,
          grid: ViewIconTypes.GRID,
          block: ViewIconTypes.BLOCK,
        }

        // Handle flowNode type resources
        if (resource.metaType === 'flowNode') {
          return nodeTypeFor(resource.type) ?? ViewIconTypes.UNKNOWN
        }

        // For all other resources, check type first, then metaType if available
        return iconMap[resource.type] ?? 
               (resource.metaType && iconMap[resource.metaType]) ?? 
               ViewIconTypes.UNKNOWN
      },
      displayValue(item) {
        return columnTypes.resource.displayFormat(item)
      }
    },
    components: {
      ViewIcon
    }
  }
</script>

<style lang="scss" scoped>

</style>