<template>
  <v-card
    flat
    class="d-flex align-center"
    :to="spaceRoute"
    hover
    width="100%"
  >
    <div class="mr-4">
      <SpaceIconMenu :spaceUri="spaceUri" offset-x right offset-y bottom>
        <template v-slot:activator="{on}">
          <SpaceIcon :space="space" @click.prevent="on.click"/>
        </template>
      </SpaceIconMenu>
    </div>
    <div class="flex-grow-1">
      <div class="text-subtitle-1 no-overflow">{{ spaceName }}</div>
    </div>
    <div v-if="realSpace" class="mr-4">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <Avatar v-if="userId" size="20" :userId="userId" :email="userEmail" class="mr-1"></Avatar>
            <v-icon v-else color="grey" small>mdi-account-multiple-outline</v-icon>
          </div>
        </template>
        <span>{{ ownerName }}</span>
      </v-tooltip>
    </div>
    <SpaceSettingsMenu
      :spaceUri="spaceUri"
      :spaceName="spaceName"
      :realSpaceUri="realSpace"
    />
    <v-icon color="grey" class="ml-2">mdi-chevron-right</v-icon>
  </v-card>
</template>

<script>
import SpaceIcon from './SpaceIcon.vue'
import SpaceIconMenu from './SpaceIconMenu.vue'
import SpaceSettingsMenu from './SpaceSettingsMenu.vue'
import Avatar from '@/components/user/Avatar.vue'

export default {
  name: 'SpaceListItem',
  components: {
    SpaceIcon,
    SpaceIconMenu,
    SpaceSettingsMenu,
    Avatar
  },
  props: {
    space: {
      type: Object,
      required: true
    },
    spaceUri: {
      type: String,
      required: true
    },
    spaceName: {
      type: String,
      required: true
    },
    realSpace: {
        type: String,
        required: true
    }
  },
  computed: {
    spaceRoute() {
      return {
        name: 'Space',
        params: {
          spaceUri: this.realSpace || this.spaceUri,
          sharedSpaceUri: this.realSpace ? this.spaceUri : undefined
        }
      }
    },
    owner() {
      return this.space.sharedBy
    },
    userId() {
      return this.owner?.id
    },
    userEmail() {
      return this.owner?.email
    },
    ownerName() {
      if (this.owner == null) return ''
      return `${this.owner?.firstName} ${this.owner?.lastName}`
    }
  }
}
</script>

<style scoped>
.no-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style> 