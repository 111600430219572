<template>
  <div data-testid="SpacesView">
    <v-app-bar app dense flat color="white">
    
    <!-- Toggle Button for Side Navigation -->
    <v-btn icon @click="spacesSideNavigation = !spacesSideNavigation">
      <v-icon>{{ spacesSideNavigation ? 'mdi-menu-open' : 'mdi-menu' }}</v-icon>
    </v-btn>

    <!-- Welcome Text with Shrink and Truncate -->
    <v-toolbar-title class="font-weight-medium">
        {{ welcomeMessage }}
    </v-toolbar-title>

  <!-- Spacer to Push User Profile to the Right -->
  <v-spacer></v-spacer>

  <!-- User Profile Component on the Right Side -->
  <UserProfile></UserProfile>
</v-app-bar>


    <FullHeightLoader :value="loading" :fullScreen="true"/>
    
    <div class="pa-4" v-if="!loading && user">

      <PaywallBanner
        v-show="!this.$vuetify.breakpoint.mobile"
        :feature="$apptive.constants.features.DONT_SHOW_UPGRADE_BANNER"
        :text="$t('paywall.upgardeBanner.longHint')"
        class="ma-4"
      ></PaywallBanner>

      <div class="d-flex align-center justify-space-between mb-4">
        <v-btn
          v-if="allSpaces.length"
          data-testid="showCreateSpaceModalButton"
          @click="showCreateSpaceDialog = true"
          text
          light
          large
          elevation="0"
          color="primary"
          :disabled="!canCreateSpaces"
        >
          {{ $t("spaces.createNewSpaceButton") }}
        </v-btn>
        <div class="d-flex align-center">

        <!-- View Toggle -->
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          icon
          @click="viewMode = viewMode === 'grid' ? 'list' : 'grid'"
          class="mr-2"
        >
          <v-icon color="accent">
            {{ viewMode === 'grid' ? 'mdi-format-list-bulleted' : 'mdi-view-grid-outline' }}
          </v-icon>
        </v-btn>

        <!-- Search Input -->
        <v-text-field
          v-if="!$vuetify.breakpoint.mobile && allSpaces.length > 10"
          v-model="searchTerm"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('spaces.searchPlaceholder')"
          class="mr-4"
          style="max-width: 300px;"
          hide-details
          dense solo 
          elevation="0"
          single-line
        ></v-text-field>
    </div>
      </div>

      <CreateSpaceDialog
        v-on="handlers"
        :show="this.showCreateSpaceDialog"
      ></CreateSpaceDialog>
      <CreateGridDialog
        v-on="handlers"
        :show="this.showCreateGridDialog"
        :spaceUri="this.spaceUri"
      ></CreateGridDialog>

        <AGDraggable
          v-if="spaces.length"
          @end="onSpaceDragEnd"
          :class="{'row no-gutters': viewMode === 'grid'}"
        >
          <template v-for="space in spaces">
            <!-- Separator -->
            <v-col
              v-if="space.isSeparator"
              :key="space.uri"
              cols="12"
              class="px-3 py-2"
            >
              <div class="text-subtitle-2 font-weight-medium grey--text text--darken-3">
                {{ space.belongsTo }}
              </div>
            </v-col>
            <!-- Grid View -->
            <v-col
              v-else-if="viewMode === 'grid'"
              :key="space.uri"
              cols="6"
              sm="4"
              md="3"
              lg="2"
              xl="1"
            >
              <SpaceThumbnail :space="space" :spaceUri="space.uri" :spaceName="space.name" :realSpace="space.realSpace"/>
            </v-col>
            <!-- List View -->
            <v-col
              v-else
              :key="space.uri"
              cols="12"
              class="px-0"
            >
              <SpaceListItem
                class="px-2"
                :space="space"
                :spaceUri="space.uri"
                :spaceName="space.name"
                :realSpace="space.realSpace"
              />
            </v-col>
          </template>
        </AGDraggable>

         <!-- empty state. no spaces -->
         <div v-if="user && !allSpaces.length" class="d-flex flex-column align-center justify-center mt-12">
          <v-img src="/apptivegrid-launch.svg"></v-img>
          <div class="text-h6 grey--text">
              {{ $t("spaces.emptyStateHeadline") }}
          </div>
          <v-btn
            data-testid="showCreateSpaceModalButton"
            color="primary"
            class="mt-3"
            @click="showCreateSpaceDialog = true">
              {{ $t("spaces.createNewSpaceButton") }}</v-btn>
         </div>
    </div>
    <OnboardingCustomer v-if="user && this.$settings.apptivecom" :user=user></OnboardingCustomer>
  </div>
</template>

<script>
import CreateSpaceDialog from '../components/CreateSpaceDialog.vue'
import CreateGridDialog from '../components/CreateGridDialog.vue'
import SpaceThumbnail from '../components/space/SpaceThumbnail.vue'
import SpaceListItem from '../components/space/SpaceListItem.vue'
import UserProfile from '../components/user/UserProfile.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import FullHeightLoader from '../components/FullHeightLoader.vue'
import AGDraggable from '../components/AGDraggable.vue'
import { bootIntercom } from '@/plugins/intercom.js'
import PaywallBanner from '../components/paywall/PaywallBanner.vue'
import OnboardingCustomer from '../components/onboarding/OnboardingCustomer.vue'
import welcomeMessage from '@/mixins/welcomeMessage.js'

export default {
  data() {
    return {
      loading: false,
      showCreateSpaceDialog: false,
      showCreateGridDialog: false,
      spaceUri: null,
      handlers: {
        showSpaceDialog: this.showSpaceDialog,
        showGridDialog: this.showGridDialog,
        createGridDialog: this.createGridDialog
      },
      appName: null,
      searchTerm: '',
      viewMode: 'grid'
    }
  },
  mixins: [welcomeMessage],
  async mounted() {
    this.loading = true
    try {
      this.appName = this.$settings.appName || 'ApptiveGrid'
      document.title = this.appName
      await this.$store.dispatch('loadUser')
      const spacesUri = this.$store.getters.spacesUri
      await this.$store.dispatch('loadSpaces', spacesUri)
      const metaDescription = document.querySelector('meta[name="theme-color"]')
      if( metaDescription) {
        metaDescription.setAttribute('content', this.$vuetify.theme.themes.light.backgoundColor)
      }
    } finally {
      this.loading = false
    }
    await bootIntercom({ appId: this.$settings.intercom.appID })
    this.reportNumberOfSpaces()
            
  },
  provide() {
    return {
      plan: () => this.$store.getters.userPlan,
    }
  },
  computed: {
    email() {
      return this.$store.state.user.email
    },
    allSpaces() {
      return this.$store.getters.spacesList
    },
    spaces() {
      const spacesList = this.$store.getters.spacesList
      let filtered = spacesList
      const view = this.$route.query.view
      
      if (view === 'all') {
        filtered = spacesList
      } else if (view === 'recent') {
        filtered = spacesList
      } else if (view === 'shared') {
        filtered = spacesList.filter(space => space.realSpace)
      }

      // Filter by search term
      if (this.searchTerm) {
        const searchLower = this.searchTerm.toLowerCase()
        filtered = filtered.filter(space => 
          space.name.toLowerCase().includes(searchLower)
        )
      }

      return filtered
    },
    user() {
      return this.$store.state.user.user
    },
    canCreateSpaces() {
      return hasPermission(this.user, [PERMISSIONS.addSpace])
    },
    spacesSideNavigation: {
      get() {
        return this.$store.state.spacesSideNavigation
      },
      set(newVal) {
        return this.$store.commit('setSpacesSideNavigation', newVal)
      }
    },
  },
  methods: {
    showSpaceDialog(bool) {
      this.showCreateSpaceDialog = bool
    },
    showGridDialog(bool) {
      this.showCreateGridDialog = bool
    },
    createGridDialog(space) {
      this.spaceUri = space.uri
      this.showCreateGridDialog = true
    },
    reportNumberOfSpaces(){
      this.$intercom?.update({
        spaces: this.spaces?.length
      })
    },
    async onSpaceDragEnd(event) {
      if (event.oldIndex === event.newIndex) return
      const spaceUri = this.spaces[event.oldIndex].uri
      // Update position
      await this.$store.dispatch('AGChangeSpacePositionOperation', {
        spaceUri,
        newPosition: event.newIndex
      })
      const spacesUri = this.$store.getters.spacesUri
      await this.$store.dispatch('loadSpaces', spacesUri)
    }
  },
  components: {
    CreateSpaceDialog,
    CreateGridDialog,
    SpaceThumbnail,
    SpaceListItem,
    UserProfile,
    FullHeightLoader,
    AGDraggable,
    PaywallBanner,
    OnboardingCustomer
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
