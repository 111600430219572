// src/mixins/resizableMixin.js
import debounce from '@/utils/debounce'

export default {
  data() {
    return {
      resizeObserver: undefined,
      heightInit: false,
    }
  },
  computed: {
    resizableContainerSizeStyle() {
        if (!this.block.height) {
          return null
        }
        return {
          height: this.block.height,
        }
    }
  },
  methods: {
    initResizeObserver(refName) {
      const debouncedSetSize = debounce(this.setSize, 500)
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentBoxSize) {
            const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize
            if (!this.heightInit) {
              this.heightInit = true
              return
            }
            debouncedSetSize(contentBoxSize.blockSize)
          }
        }
      })
      this.resizeObserver.observe(this.$refs[refName])
    },
    cleanupResizeObserver(refName) {
      this.resizeObserver?.unobserve(this.$refs[refName])
    },
    setSize(height) {
      this.loading = true
      try {
        this.block.setHeight(`${height}px`)
        this.block.updateValue()
      } finally {
        this.loading = false
      }
    }
  },
  beforeDestroy() {
    this.cleanupResizeObserver('resizableContainer') // Adjust the ref name as needed
  }
}