<template>
  <BaseStatefulView
    :persistentGridUri="gridUri"
    :virtualGridUri="virtualGridUri"
    :viewTitle="virtualGrid ? virtualGrid.name : ''"
    @reloadView="reloadVirtualGrid"
    :showToolbar="showToolbar"
    :embedded="embedded"
    :autoreload="autoreload"

  >
    <div v-if="!loading && virtualGrid" class="d-flex flex-column ml-5 full-height"  @click="onClickOutsideGrid">
      <RecycleScroller
        v-if="!isGrouped"
        v-bind="scrollerProps"
        ref="scroller"
        class="scroller flex-grow-0"
        keyField="_id"
        v-bind:style="{ width: '100%', height: '100%'}"
        @scroll.native.passive="handleScroll"
        :key="virtualGrid.scrollerKey"
        data-testid="virtualGrid"
      >
      <template #before>
          <GridHeaderRow
            class="flex-grow-0"
            :virtualGrid="virtualGrid"
            :filteredColumnIds="filteredColumnIds"
            :sortedColumnIds="sortedColumnIds"
            v-on:addColumn="addColumn"
            :addColumnLoading="addColumnLoading"
            :disableAddColumn="!canAddField"
            :readonly="isStandalone"
          />
        </template>
        <template v-slot="{ item, active }">
            <GridRow
              :readonly="!canUpdateEntity(item)"
              :ref="'rows' + indexOfEntity(item)"
              :entity="item"
              :virtualGrid="virtualGrid"
              :filteredColumnIds="higlightedFilteredColumnIds"
              :selectedCellIndex="selectedRowId === item._id ? selectedColumn : undefined"
              v-on:entityChanged="entityChanged"
              v-on:cellBlurred="updateEntity(item)"
              v-on:nextLine="(columnIndex)=>selectCell(indexOfEntity(item) + 1, columnIndex)"
              @selectCell="(columnIndex) => selectCell(indexOfEntity(item), columnIndex)"
              @contextmenu.native="event => openRowMoreMenu(event, item, indexOfEntity(item))"
              @openEditEntityDialog="openEditEntityDialog"
              @showError="showCellError"
              @hideError="hideCellError"
              :data-testid="active ? `row-${indexOfEntity(item)}` : ''"
            />
        </template>
        <template #after>
          <ProvisionalGridRow
            v-if="provisionalEntity && (statefulView && !isGrouped)"
            :noBottomBorder="true"
            :entity="provisionalEntity"
            :virtualGrid="virtualGrid"
            :filteredColumnIds="higlightedFilteredColumnIds"
            v-on:entityChanged="entityChanged"
            v-on:cellBlurred="updateEntity(provisionalEntity)"
            v-on:didFinishEditingEntity="provisionalRowDidFinishEditing"
            :selectedCellIndex="selectedRowId === provisionalEntity._id ? selectedColumn : undefined"
            @openEditEntityDialog="openEditEntityDialog"
            @selectCell="(columnIndex) => selectCell(shownEntities.length, columnIndex, true)"
            :containerScrollLeft="lastScrollLeft"
          />
          <PaywallMenu
            :feature="$apptive.constants.features.ENTITIES_PER_GRID"
            :count="gridEntityCount"
          >
            <GridFooterRow
              v-if="!isStandalone"
              class="flex-grow-0"
              :disabled="!canAddEntity"
              :width="virtualGrid.gridWidth()"
              :loading="addRowLoading"
              v-on:addRow="addRow"
              :virtualGrid="virtualGrid"
              :addButton="!isGrouped"
            />
          </PaywallMenu>
        </template>
      </RecycleScroller>
      <div
        class="grouped-grid-view"
        ref="groupedContainer"
        v-else
      >
      <div class="grouped-body">
          <GridHeaderRow
            class="flex-grow-0 grouped-header"
            :virtualGrid="virtualGrid"
            :filteredColumnIds="filteredColumnIds"
            :sortedColumnIds="sortedColumnIds"
            v-on:addColumn="addColumn"
            :addColumnLoading="addColumnLoading"
            :disableAddColumn="!canAddField"
            :readonly="isStandalone"
          />
          <template v-for="(item) in groupedItems">
            <div :key="item._id">
              <EntityGroupHeader
                v-if="item.type === 'header'"
                :virtualGrid="virtualGrid"
                :group="item"
                :parent-width="scrollerWidth"
              />
              <PaywallMenu
                v-if="item.type === 'footer'"
                :feature="$apptive.constants.features.ENTITIES_PER_GRID"
                :count="gridEntityCount"
              >
                <EntityGroupFooter
                  :virtualGrid="virtualGrid"
                  :statefulView="statefulView"
                  :group="item"
                  :entities="groupedEntities[item.group]"
                  @addRow="addRowToGroup(item.group)"
                  :loading="addRowLoading"
                />
              </PaywallMenu>
              <ProvisionalGridRow
                v-else-if="item.provisional"
                :ref="'rows' + indexOfEntity(item)"
                :entity="provisionalEntity"
                :virtualGrid="virtualGrid"
                :filteredColumnIds="higlightedFilteredColumnIds"
                v-on:entityChanged="entityChanged"
                v-on:cellBlurred="updateEntity(provisionalEntity)"
                v-on:didFinishEditingEntity="provisionalRowDidFinishEditing"
                :selectedCellIndex="selectedRowId === item._id ? selectedColumn : undefined"
                @openEditEntityDialog="openEditEntityDialog"
                @selectCell="(columnIndex) => selectCell(indexOfEntity(item), columnIndex, true)"
                :containerScrollLeft="lastScrollLeft"
              />
              <GridRow
                v-else-if="!item.provisional"
                :readonly="!canUpdateEntity(item)"
                :ref="'rows' + indexOfEntity(item)"
                :entity="item"
                :virtualGrid="virtualGrid"
                :filteredColumnIds="higlightedFilteredColumnIds"
                :selectedCellIndex="selectedRowId === item._id ? selectedColumn : undefined"
                v-on:entityChanged="entityChanged"
                v-on:cellBlurred="updateEntity(item)"
                v-on:nextLine="(columnIndex)=>selectCell(indexOfEntity(item) + 1, columnIndex)"
                @selectCell="(columnIndex) => selectCell(indexOfEntity(item), columnIndex)"
                @contextmenu.native="event => openRowMoreMenu(event, item, indexOfEntity(item))"
                @openEditEntityDialog="openEditEntityDialog"
                @showError="showCellError"
                @hideError="hideCellError"
                :data-testid="`row-${indexOfEntity(item)}`"
              />
            </div>
          </template>
        </div>
        
      </div>
    </div>
    <GridRowMoreMenu
      v-model="showRowMoreMenu"
      :position-x="menuX"
      :position-y="menuY"
      class="flex-grow-0"
      :virtualGrid="virtualGrid"
      :entity="contextMenuEntity"
      absolute
      dark
      style="max-width: 600px"
      />
    <v-menu
      v-model="showCellErrorMenu"
      :position-x="cellErrorX"
      :position-y="cellErrorY"
    >
      <v-sheet class="red--text pa-3">{{ cellError }}</v-sheet>
    </v-menu>
    <EditEntityDialog
      v-model="editEntityDialog"
      :virtualGrid="virtualGrid"
      :entity="editingEntity"
      :createEntityMode="isCreateEntityMode"
    >
      <template v-slot:titleBarLeft v-if="!isCreateEntityMode">
        <span class="text-caption accent--text text--lighten-2 entity-index mr-3">{{editingEntity ? editingEntity.position : ''}}</span>
        <v-btn :disabled="editEntityFirst" @click="previousEntity" icon > <v-icon>mdi-chevron-up</v-icon></v-btn>
        <v-btn :disabled="editEntityLast" @click="nextEntity" icon><v-icon>mdi-chevron-down</v-icon></v-btn>
      </template>
    </EditEntityDialog>
    <EntitiesPaywallDialog
      v-model="showEntitiesPaywall"
      :feature="$apptive.constants.features.ENTITIES_PER_GRID"
      :count="gridEntityCount"
      :text="$t('paywall.entitiesPerGrid.longHint')"
    />
  
    <template v-slot:toolbar-left v-if="!loading && !isStandalone">
      <VirtualGridToolbar
        :virtualGrid="virtualGrid"
        :statefulViewUri="statefulViewUri"
        @filterMouseEnter="highlightFilteredColumns = true"
        @filterMouseLeave="highlightFilteredColumns = false"
        :labels="toolbarLabels"
      />
      <GridGroupingMenu
        :disabled="!canUpdateView"
        :statefulView="statefulView"
        :label="toolbarLabels"
      />
      <VirtualGridMoreMenu
        :virtualGrid="virtualGrid"
        :disableClone="!canAddView"
        :disableDelete="!canDeleteView"
        :disableRename="!canUpdateView"
        :disableImport="!canAddEntity"
        :disableEditFieldKeys="!canUpdateFieldKey"
        :disableSetKey="!canSetGridKey"
        :disableExtractField="!canExtractField"
        v-on:cloneVirtualGrid="cloneVirtualGrid"
        v-on:selectVirtualGrid="selectVirtualGrid"
      />
    </template>
    <FullHeightLoader class="flex-grow-1" v-model="loading"/>
    <v-btn
      v-if="canAddEntity && !embedded"
      @click="onAddFabClick"
      color="primary"
      fab fixed bottom right
      :loading="addRowLoading"
    >
      <v-icon size="42">mdi-plus</v-icon>
    </v-btn>
  </BaseStatefulView>
  </template>
  
  <script>
  import GridHeaderRow from './GridHeaderRow.vue'
  import GridFooterRow from './GridFooterRow.vue'
  import GridRow from './GridRow.vue'
  import ProvisionalGridRow from './ProvisionalGridRow.vue'
  import BaseStatefulView from '@/views/BaseStatefulView.vue'
  import VirtualGridMoreMenu from '@/components/gridView/GridViewMoreMenu.vue'
  import FullHeightLoader from '@/components/FullHeightLoader.vue'
  import GridRowMoreMenu from '@/components/gridView/GridRowMoreMenu.vue'
  import VirtualGridToolbar from '@/components/gridView/VirtualGridToolbar.vue'
  import EditEntityDialog from '@/components/gridView/EditEntityDialog.vue'
  import GridGroupingMenu from '@/components/gridView/grouping/GridGroupingMenu.vue'
  import EntityGroupHeader from './EntityGroupHeader.vue'
  import EntityGroupFooter from './EntityGroupFooter.vue'
  import EntitiesPaywallDialog from './EntitiesPaywallDialog.vue'
  import { checkFeatureLimit } from '@/mixins/paywall.js'
  import gridFilteredBus from '@/utils/gridFilteredBus'

  import Vue from 'vue'
  import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
  
  import { RecycleScroller } from 'vue-virtual-scroller'
  import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
  import PaywallMenu from '../paywall/PaywallMenu.vue'
  import viewProps from '@/mixins/viewProps'

  export default {
    mixins: [viewProps],

    data() {
      return {
        highlightFilteredColumns: false,
        contextMenuEntity: undefined,
        showRowMoreMenu: false,
        editEntityDialog: false,
        editingEntity: undefined,
        menuX: 0,
        menuY: 0,
        loading: false,
        addColumnLoading: false,
        addRowLoading: false,
        selectedRow: undefined,
        selectedColumn: undefined,
        selectedRowId: undefined,
        LOAD_THRESHOLD_PX: 9600,
        RECYCLER_BUFFER: 1000,
        GROUPED_VIEW_BUFFER: 3000,
        ROW_HEIGHT_PX: 32,
        lastScrollTop: 0,
        lastScrollLeft: 0,
        showEntitiesPaywall: false,
        showCellErrorMenu: false,
        cellError: undefined,
        cellErrorX: undefined,
        cellErrorY: undefined,
      }
    },
    mounted() {
      gridFilteredBus.$on('filterChanged', this.onFilterChanged)
    },
    beforeDestroy() {
      gridFilteredBus.$off('filterChanged', this.onFilterChanged)
    },
    watch: {
      statefulViewUri: {
        immediate: true,
        async handler() {
          this.loading = true
          try {
            const statefulView = await this.$store.dispatch('AGReadStatefulViewOperation', this.statefulViewUri)
            await this.$store.dispatch('AGReadVirtualGridOperation', statefulView.parentGridUri ?? '')
            statefulView.groupEntities()
          } finally {
            this.loading = false
          }
        }
      },
      'virtualGrid.entities': {
        handler() {
          this.statefulView?.groupEntities()
        }
      },
    },
    computed: {
      space() {
        return this.$store.getters.spaceWithUri(this.spaceUri)
      },
      plan() {
        return this.space?.plan
      },
      statefulView() {
        return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
      },
      virtualGrid() {
        return this.statefulView?.parentGrid
      },
      virtualGridUri() {
        return this.statefulView?.parentGrid?.uri
      },
      gridEntityCount() {
        return this.virtualGrid?.entitiesPager?.numberOfItems
      },
      shownEntities() {
        return this.virtualGrid?.entities.filter(aEntitiy => !aEntitiy.provisional) ?? []
      },
      provisionalEntity() {
        return this.virtualGrid.entities.find(entity => entity.provisional)
      },
      filteredColumnIds() {
        return this.virtualGrid?.filteredColumnIds() ?? []
      },
      sortedColumnIds() {
        return this.virtualGrid?.sortedColumnIds() ?? []
      },
      higlightedFilteredColumnIds() {
        return this.highlightFilteredColumns ? this.filteredColumnIds : []
      },
      grid () {
        return this.$store.getters.gridWithUri(this.gridUri)
      },
      canAddField() {
        return hasPermission(this.virtualGrid, [PERMISSIONS.addField])
      },
      canAddEntity() {
        return hasPermission(this.virtualGrid, [PERMISSIONS.addEntity])
      },
      canAddView() {
        return hasPermission(this.grid, [PERMISSIONS.addView])
      },
      canUpdateFilter() {
        return hasPermission(this.virtualGrid, [PERMISSIONS.updateFilter])
      },
      canDeleteView() {
        return hasPermission(this.virtualGrid, [PERMISSIONS.remove])
      },
      canUpdateView() {
        return hasPermission(this.virtualGrid, [PERMISSIONS.update])
      },
      canUpdateFieldKey() {
        return hasPermission(this.virtualGrid, [PERMISSIONS.updateFieldKey])
      },
      canSetGridKey() {
        return hasPermission(this.virtualGrid, [PERMISSIONS.patch])
      },
      canExtractField() {
        return this.virtualGrid?.fields.some(field => hasPermission(field, [PERMISSIONS.extractToGrid]))
      },
      isGrouped() {
        return this.statefulView?.isGrouped()
      },
      groupedItems() {
        return this.statefulView?.groupedItems
      },
      groupedEntities() {
        return this.statefulView.groups
      },
      scrollerWidth() {
        return this.isGrouped
          ? this.$refs.groupedContainer?.clientWidth
          : this.$refs.scroller?.$el?.clientWidth
      },
      scrollerProps() {
        return {
          items: this.shownEntities,
          class: {'no-overflow-y': this.shownEntities && this.shownEntities.length <= 1},
          'item-size': this.ROW_HEIGHT_PX,
          buffer: this.RECYCLER_BUFFER,
        }
      },
      editEntityFirst() {
        const index = this.virtualGrid.entities.indexOf(this.editingEntity)
        return index === 0
      },
      editEntityLast() {
        const index = this.virtualGrid.entities.indexOf(this.editingEntity)
        return index === (this.virtualGrid.entitiesPager.numberOfItems - 1)
      },
      isCreateEntityMode() {
        return this.editingEntity?._id === 'draft'
      },
    },
    provide() {
      return {
        canAddField: () => this.canAddField,
        canUpdateFieldKey: () => this.canUpdateFieldKey,
        getCurrentEntityPage: this.getCurrentEntityPage,
        space: this.space
      }
    },
    methods: {
      indexOfEntity(entity) {
        return this.shownEntities.indexOf(entity)
      },
      async baseAddRow(addRowMethod) {
        if(this.provisionalEntity) {
          this.provisionalRowDidFinishEditing(this.provisionalEntity)
        }
        this.addRowLoading = true
        try {
          await addRowMethod()
        } finally {
          this.addRowLoading = false
        }
        const isLimitReached = await checkFeatureLimit(
          this.$store,
          this.plan,
          this.$apptive.constants.features.ENTITIES_PER_GRID,
          this.gridEntityCount,
          this.space
        )
        if (isLimitReached) {
          this.showEntitiesPaywall = true
        }
      },
      async addRow() {
        return this.baseAddRow(() => this.virtualGrid.addEmptyRow())
      },
      async addRowToGroup(group) {
        return this.baseAddRow(() => this.statefulView.addRowToGroup(group))
      },
      async onAddFabClick() {
        if(this.provisionalEntity) {
          this.provisionalRowDidFinishEditing(this.provisionalEntity)
        }
        const newEntity = this.virtualGrid.emptyEntity()
        this.openEditEntityDialog(newEntity)
      },
      updateEntity(entity) {
        if(!entity) {
          return
        }
        // Do not update entity if it is about to be deleted
        if (!this.$store.state.virtualGridsModule.deletedEntityIds.includes(entity._id)) {
          this.virtualGrid.updateEntity(entity)
            .catch((error) => {
              console.error(error)
              this.reloadVirtualGrid()
            })
        }
        if (!entity.provisional) {
          const entityIndex = this.virtualGrid.entities.findIndex(aEntitiy => aEntitiy._id == entity._id)
          //If the entity was previously removed and now matches again, insert it back at its previous position
          if (this.virtualGrid.entityMatches(entity) && entityIndex === -1) {
            this.virtualGrid.entities.splice(entity.oldIndex, 0, entity)
          }
          if (!this.virtualGrid.entityMatches(entity)) {
            // the entity needs to be removed in a filtered view if it doesn't fit to the filter
            if (entityIndex >= 0) {
              entity.oldIndex = entityIndex
              this.virtualGrid.entities.splice(entityIndex, 1)
            }
          }
        }
      },
      sort() {
        this.virtualGrid.sort()
      },
      gridRowFinishedEditing(entity) {
        this.sort(entity)
      },
      provisionalRowDidFinishEditing(entity) {
        // AC-1464 When a provisional entity gets blurred and replaced by a new one at the same time,
        // the update does not occur.
        // Giving it an extra chance to update now if it is not already ongoing :
        if (!entity.updating) {
          this.updateEntity(entity)
        }
        if (!this.virtualGrid.provisionalEntityMatches(entity)) {
          // the provisional entity needs to be removed in a filtered view if it doesn't fit to the filter
          this.virtualGrid.entities.splice(this.virtualGrid.entities.findIndex(aEntitiy => aEntitiy.provisional), 1)
        } else {
          this.sort(entity)
        }
        Vue.set(entity, 'provisional', false)
      },
      entityChanged( entity , newValue , field ) {
        this.virtualGrid.entityChanged(entity, newValue , field)
      },
      addColumn(position) {
        this.addColumnLoading = position ?? 'last'
        this.virtualGrid.addColumn(position)
          .then(() => {
            return this.reloadVirtualGrid()
          })
          .finally(() => {
            this.addColumnLoading = false
          })
      },
      selectVirtualGrid(virtualGridUri) {
        return this.$store.dispatch('AGReadVirtualGridOperation', virtualGridUri)
          .then(() => {
            this.$router.push({name: 'VirtualGrid', params: {gridUri: this.gridUri, virtualGridUri: virtualGridUri}})
          })
      },
      cloneVirtualGrid() {
        this.$store.dispatch('AGAddSpreadsheetViewOperation', {
          grid: this.grid,
          virtualGridParams: {
            filterObject: (this.virtualGrid.filterObject().exportObject()),
            sorting: this.virtualGrid.sorting,
            fields: this.virtualGrid.shownFieldIds()
          }
        })
        .then((statefulView) => {
          this.$router.push({ name: 'VirtualGrid', params: { statefulViewUri: statefulView.uri } })
        })
      },
      reloadVirtualGrid() {
        this.virtualGrid.reload(this.getCurrentEntityPage())
      },
      async selectCell(rowIndex, columnIndex, provisional = false) {
        this.sort()
        await this.$nextTick()
        document.activeElement?.blur()
        const provisionalRowCount = this.provisionalEntity ? 1 : 0
        const items = this.shownEntities
        const selectedRowIndex = Math.min(rowIndex, (items.length - 1) + provisionalRowCount)
        this.selectedRowId = provisional ? this.provisionalEntity._id : this.shownEntities[selectedRowIndex]._id
        this.selectedColumn = Math.min(columnIndex, (this.virtualGrid.fields.length - 1))
      },
      openRowMoreMenu(event, entity) {
        this.menuX = event.clientX
        this.menuY = event.clientY
        this.contextMenuEntity = entity
        this.$nextTick(() => {
          this.showRowMoreMenu = true
        })
        event.preventDefault()
      },
      canUpdateEntity(entity) {
        return hasPermission(entity, [PERMISSIONS.update])
      },
      async handleScroll(e) {
        const { target } = e
        const currentScroll = target.scrollTop
        const scrollableDistance = Math.max(0, target.scrollHeight - target.offsetHeight)
        const scrollingDown = currentScroll - this.lastScrollTop >= 0
        if (scrollingDown && currentScroll >= scrollableDistance - this.LOAD_THRESHOLD_PX) {
          await this.loadNext()
        } else if (!scrollingDown && currentScroll <= this.LOAD_THRESHOLD_PX) {
          await this.loadPrevious()
        }
        this.lastScrollTop = currentScroll
        this.lastScrollLeft = target.scrollLeft
      },
      loadNext() {
        return this.virtualGrid.entitiesPager.loadNext()
      },
      loadPrevious() {
        return this.virtualGrid.entitiesPager.loadPrevious()
      },
      getCurrentEntityPage() {
        // Paging is currently not supported with grouped views
        if (this.isGrouped) {
          return 1
        }
        const currentScroll = this.$refs.scroller.$el.scrollTop
        const entityIndex = currentScroll / this.ROW_HEIGHT_PX
        return this.virtualGrid.entitiesPager?.pageOf(entityIndex)
      },
      openEditEntityDialog(entity) {
        this.editEntityDialog = true
        this.editingEntity = entity
      },
      onFilterChanged() {
        this.$refs.scroller.$el.scrollTop = 0
      },
      previousEntity() {
        const index = this.virtualGrid.entities.indexOf(this.editingEntity)
        if (index === 0) {
          return
        }
        this.editingEntity = this.virtualGrid.entities[index - 1]
      },
      async nextEntity() {
        const index = this.virtualGrid.entities.indexOf(this.editingEntity)
        if (index === (this.virtualGrid.entities.length - 1)) {
          await this.loadNext()
        }
        this.editingEntity = this.virtualGrid.entities[index + 1]
      },
      showCellError(clickEvent, error) {
        this.cellError = error
        this.showCellErrorMenu = true
        const mousePointerOffset = 20
        this.cellErrorX = clickEvent.clientX + mousePointerOffset
        this.cellErrorY = clickEvent.clientY + mousePointerOffset
      },
      hideCellError() {
        this.showCellErrorMenu = false
      },
      onClickOutsideGrid() {
        if(this.provisionalEntity) {
          this.provisionalRowDidFinishEditing(this.provisionalEntity)
        }
      }
    },
    components: {
    GridHeaderRow,
    GridRow,
    ProvisionalGridRow,
    GridFooterRow,
    BaseStatefulView,
    VirtualGridMoreMenu,
    GridRowMoreMenu,
    FullHeightLoader,
    VirtualGridToolbar,
    EditEntityDialog,
    GridGroupingMenu,
    EntityGroupHeader,
    EntityGroupFooter,
    EntitiesPaywallDialog,
    PaywallMenu,
    RecycleScroller
}
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped >
  
  .full-height {
    height: 100%;
  }
  
  .no-overflow-y {
    overflow-x: visible;
    overflow-y: hidden !important;
  }
  
  .sticky {
    position: sticky;
    top: 0;
  
  }
  
  /deep/ .vue-recycle-scroller__item-wrapper {
    overflow-x: unset;
    overflow-y: unset;
  }
  
  /deep/ .vue-recycle-scroller__slot:first-of-type {
    position: sticky;
    top: 0;
    z-index: var(--grid-toolbar-zindex);
  }
  
  /deep/ .vue-recycle-scroller__slot {
    position: sticky;
    bottom: 0;
    z-index: var(--grid-toolbar-zindex);
  }

  .entity-index {
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
    border-color: #DEE1E3;
    background-color: white;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .grouped-grid-view {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .grouped-body {
    overflow-x: auto;
  }

  .grouped-header {
    position: sticky;
    top: 0px;
    z-index: var(--grid-toolbar-zindex);
  }
  </style>
  