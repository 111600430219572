export class ASHolder {
  // HACK : this is used to keep the curly braces around
  // an expression when building a SpecialisedHttpRequestNode mapped property
  // (see SpecialisedHttpRequestNode:updateProperties)
  get componentOutputValue() {
    return this._value
  }
  unwrap() {
    const value = this._value
    
    if (Array.isArray(value)) {
      return value.map(item => item instanceof ASHolder ? item.unwrap() : item)
    }
    
    if (value && typeof value === 'object') {
      const result = {}
      for (const [key, val] of Object.entries(value)) {
        result[key] = val instanceof ASHolder ? val.unwrap() : val
      }
      return result
    }
    
    return value
  }
}