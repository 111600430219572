<template>
  <v-icon  class="viewIcon" :class="{ 'bordered' : bordered }" :color='type.color' :size="size">{{type.icon}}</v-icon>
</template>
<script>

export default {
  props: {
    type: null,
    bordered: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style lang="css" scoped>
.viewIcon {
  font-size: 20px;
}
.bordered {
  border: 1px solid #e4e5e4;
  border-radius: 20%;
  padding: 2px;
  background-color: white;
}
</style>
