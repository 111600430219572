<template>
  <BaseFormInput v-bind="$props" :hide-label="disabled && isExternalHookModel">
    <template v-if="disabled && isExternalHookModel">
      <v-btn
        text
        :color="buttonColor"
        @click="openResource"
        class=""
      >
        <v-icon small class="mr-2">mdi-open-in-new</v-icon>
        {{ buttonText }}
      </v-btn>
    </template>
    <v-select
      v-else
      ref="input"
      v-model="model"
      class="invisible"
      :items="items"
      item-value="_links.self.href"
      item-text="displayValue"
      :label="fieldStyle.labelAbove ? null : label"
      :rules="[rules.required, rules.schema]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      @blur="finishEditing"
      :color="color"
      :disabled="disabled"
      :loading="loading"
      v-bind="fieldStyle"
      clearable
      @click:clear="clear"
    />
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import BaseFormInput from '@/components/form/BaseFormInput.vue'
import externalModel from '@/mixins/externalModel'
import resourceInputMixin from '@/mixins/resourceInputMixin'

export default {
  mixins: [formFields, externalModel, resourceInputMixin],
  data() {
    return {
      items: [],
      loading: false
    }
  },
  mounted() {
    this.loadResources()
  },
  computed: {
    model: {
      get() {
        if (!this.externalModel || !this.items.length) return null

        const modelHref = this.externalModel?._links?.self?.href || this.externalModel?.href
        return this.items.find(item =>
          item._links?.self.href === modelHref
        ) || null
      },
      set(newVal) {
        if (newVal == null) {
          this.externalModel = null
          return
        }
        this.externalModel = { href: newVal }
      }
    },
    buttonText() {
      return this.externalModel?.displayValue || 'Select'
    },
    isExternalHookModel() {
      return this.externalModel && this.externalModel?.metaType === 'externalHook'
    },
  },
  methods: {
    clear() {
      this.externalModel = null
      this.finishEditing()
    },
    finishEditing() {
      this.$emit('blur')
    },
    openResource() {
      if (this.externalModel?.redirectUri) {
        window.open(`${window.location.origin}${this.externalModel.redirectUri}`, '_blank')
      }
    },
  },
  components: {
    BaseFormInput
  }
}
</script>
