<template>
  <div>
    <v-hover v-slot="{ hover }">
      <div class="d-flex align-center py-2" :class="{ 'on-hover': hover && !isSelected }">
        <v-btn 
          @click.prevent="handleClick"          
          icon 
          x-small 
          color="accent" 
          class="mr-0 ml-1"
          
        >
          <v-icon>{{ isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
        </v-btn>

        <div
          @click="navigateToGrid"
          class="flex-grow-1 pr-1 text-decoration-none black--text cursor-pointer subtitle-2"
          :class="{ 'font-weight-bold': isSelected }"
          :data-testid="`gridNavigationItem-${index}`"
        >
          <EditableText
            :text="displayName"
            @submit="newName => renameGrid(newName)"
            :readonly="!canRename"
          />
      </div>

        <v-menu
          offset-y
          left
          v-if="(hover || showMenu) && canAddView"
          v-model="showMenu"
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              x-small
              v-bind="attrs"
              v-on="on"
              class="mr-1"
              data-testid="createViewsExpandButton"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item data-testid="createVirtualGrid" @click="createVirtualGrid" :disabled="!canAddView">
              <v-list-item-icon class="mr-2">
                <ViewIcon :type="ViewIconTypes.GRID" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t('navigationDrawer.newVirtualGrid') }}</v-list-item-title>
            </v-list-item>

            <v-list-item data-testid="createForm" @click="createForm" :disabled="!canAddForm">
              <v-list-item-icon class="mr-2">
                <ViewIcon :type="ViewIconTypes.FORM" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t('navigationDrawer.newForm') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="createKanban" :disabled="!canAddView">
              <v-list-item-icon class="mr-2">
                <ViewIcon :type="ViewIconTypes.KANBAN" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t('kanban.kanbanBoard') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="createCalendar" :disabled="!canAddView">
              <v-list-item-icon class="mr-2">
                <ViewIcon :type="ViewIconTypes.CALENDAR" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t('calendar.title') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="createMap" :disabled="!canAddView">
              <v-list-item-icon class="mr-2">
                <ViewIcon :type="ViewIconTypes.MAP" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t('map.title') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="createGalleryView" :disabled="!canAddView">
              <v-list-item-icon class="mr-2">
                <ViewIcon :type="ViewIconTypes.GALLERY" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t('gallery.title') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <GridMoreMenu
          v-if="canRename"
          class="mr-1 my-0"
          style="line-height: 1.0 !important;"
          v-show="hover || showMenu"
          :gridUri="gridUri"
          :disableDelete="!canDeleteGrid"
          :disableRename="!canRename"
          @deleteGrid="deleteGrid()"
        />
      </div>
    </v-hover>

    <div v-if="isExpanded" class="pl-6">
        <GridViewsNavigation
            :space="space"
            :gridUri="gridUri"
            :loading="false"
            :active="isSelected"
        />
        </div>
  </div>
</template>

<script>
import EditableText from '@/components/EditableText.vue'
import GridViewsNavigation from './GridViewsNavigation.vue'
import ViewIcon from '@/components/ViewIcon.vue'
import {ViewIconTypes} from '@/constants/viewIconTypes.js'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import statefulViewTypes  from '@/constants/statefulViewTypes.js'
import GridMoreMenu from '@/components/grid/GridMoreMenu.vue'
export default {
  name: 'GridNavigationItem',

  components: {
    EditableText,
    GridViewsNavigation,
    ViewIcon,
    GridMoreMenu
  },

  props: {
    gridUri: {
      type: String,
      required: true
    },
    gridName: {
      type: String,
      required: true
    },
    space: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      expanded: undefined,
      showMenu: false,
    }
  },

  watch: {
    isSelected(newVal) {
      if (newVal && this.expanded === undefined) {
        this.expanded = true
      }
    }
  },

  computed: {
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    hasViews() {
      return this.$store.getters.statefulViewsOfGrid(this.gridUri).length > 0
    },
    displayName() {
      return this.grid?.name || this.gridName
    },
    isSelected() {
      return this.$route.params.gridUri === this.gridUri
    },
    isExpanded() {
      return this.expanded === true || (this.isSelected && this.expanded === undefined)
    },
    canRename() {
      return hasPermission(this.grid, [PERMISSIONS.rename])
    },
    canAddView() {
      return hasPermission(this.grid, [PERMISSIONS.addView])
    },
    canAddForm() {
      return hasPermission(this.grid, [PERMISSIONS.addForm])
    },
    canDeleteGrid() {
      return hasPermission(this.grid, [PERMISSIONS.remove])
    },

    ViewIconTypes() {
      return ViewIconTypes
    }
  },

  methods: {
    // Handles navigation when a grid item is clicked
    navigateToGrid() {
      // Expand the grid item to show its child views
      this.expanded = true
      
      // If we're already on this grid, don't navigate again
      if (this.isSelected) return
      
      // Navigate to the Grid route with the selected grid's URI
      this.$router.push({ name: 'Grid', params: { gridUri: this.gridUri }})
    },
    handleClick() {
        if (!this.hasViews) {
            this.navigateToGrid()
        } else {
            this.toggleExpanded()
        }
    },
    toggleExpanded() {
      this.expanded = !this.expanded    
    },

    async deleteGrid() {
      await this.$store.dispatch('AGDeleteGridOperation', this.gridUri)
      this.$router.push({ name: 'Space', params: { spaceUri: this.space.uri }})
    },

    async renameGrid(newName) {
      if (this.grid.name === newName) return
      await this.$store.dispatch('AGRenameGridOperation', { 
        gridUri: this.gridUri, 
        newName 
      })
    },
    async createForm() {
      this.loadingForm = true
      let form
      try {
        form = await this.$store.dispatch('AGAddFormOperation', {gridUri: this.gridUri})
      } finally {
        this.loadingForm = false
      }
      this.$intercom?.trackEvent( 'User created a form' )
      this.$store.dispatch('loadFormUris', this.grid.uri)
      this.$router.push({name: 'FormBuilder', params: { formUri: form.uri, gridUri: this.gridUri }})
    },
    async createVirtualGrid() {
      this.loadingViewIconType = ViewIconTypes.GRID
      let statefulView
      try {
        statefulView = await this.$store.dispatch('AGAddSpreadsheetViewOperation', {
          grid: this.grid
        })
      } finally {
        this.loadingViewIconType = null
      }
      this.$router.push({
        name: 'VirtualGrid',
        params: {
          gridUri: this.gridUri,
          virtualGridUri: statefulView.parentGrid.uri,
          statefulViewUri: statefulView.uri
      }})
    },
    createKanban() {
      this.$intercom?.trackEvent( 'User created kanban board' )
      this.loadingViewIconType = ViewIconTypes.KANBAN
      this.$store.dispatch('AGAddStatefulViewOperation', {
        grid: this.grid,
        name: 'Kanban',
        type: statefulViewTypes.kanban.name,
        fieldIds: this.grid.fields.map(field => field.id),
        properties: { version: statefulViewTypes.kanban.version }
      })
        .then((statefulView) => this.$router.push({
          name: 'KanbanView',
          params: {
            virtualGridUri: this.$store.getters.gridUriContainingStatefulView(statefulView.uri),
            statefulViewUri: statefulView.uri
          }
        }))
        .finally(() => this.loadingViewIconType = false)
    },
    createCalendar() {
      this.$intercom?.trackEvent( 'User created calendar' )
      this.loadingViewIconType = ViewIconTypes.CALENDAR
      this.$store.dispatch('AGAddStatefulViewOperation', {
        grid: this.grid,
        name: 'Calendar',
        type: statefulViewTypes.calendar.name,
        fieldIds: this.grid.fields.map(field => field.id),
        properties: { version: statefulViewTypes.calendar.version }
      })
        .then((statefulView) => this.$router.push({
          name: 'CalendarView',
          params: {
            virtualGridUri: this.$store.getters.gridUriContainingStatefulView(statefulView.uri),
            statefulViewUri: statefulView.uri
          }
        }))
        .finally(() => this.loadingViewIconType = false)
    },
    createMap() {
      this.$intercom?.trackEvent( 'User created map' )
      this.loadingViewIconType = ViewIconTypes.MAP
      this.$store.dispatch('AGAddStatefulViewOperation', {
        grid: this.grid,
        name: 'Map',
        type: statefulViewTypes.map.name,
        fieldIds: this.grid.fields.map(field => field.id),
        properties: { version: statefulViewTypes.map.version }
      })
        .then((statefulView) => this.$router.push({
          name: 'MapView',
          params: {
            virtualGridUri: this.$store.getters.gridUriContainingStatefulView(statefulView.uri),
            statefulViewUri: statefulView.uri
          }
        }))
        .finally(() => this.loadingViewIconType = false)
    },
    createGalleryView() {
      this.$intercom?.trackEvent( 'User created gallery board' )
      this.loadingViewIconType = ViewIconTypes.GALLERY
      this.$store.dispatch('AGAddStatefulViewOperation', {
        grid: this.grid,
        name: 'Gallery',
        type: statefulViewTypes.gallery.name,
        fieldIds: this.grid.fields.map(field => field.id),
        properties: { version: statefulViewTypes.gallery.version }
      })
        .then((statefulView) => this.$router.push({
          name: 'GalleryView',
          params: {
            virtualGridUri: this.$store.getters.gridUriContainingStatefulView(statefulView.uri),
            statefulViewUri: statefulView.uri
          }
        }))
        .finally(() => this.loadingViewIconType = false)
    },
  }
}
</script> 
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.on-hover {
  background-color: #e3e3e3;
  border-radius: 12px;
}

</style>