<template>
  <BaseBlock
    :blockUri="blockUri"
    v-on="$listeners"
    :editorMode="editorMode"
  >
    <template v-slot:menu>
      <MenuItem
        icon="mdi-asterisk"
        :text="$t('blocks.inputBlock.requiredVideo')"
        showSwitch
        v-model="isRequired"
      />
      <MenuItem
        icon="mdi-cog-outline"
        :text="$t('blocks.blockSettings')"
        @click="openMenu"
      />
    </template>
    <div
      v-if="!videoUrl"
      class="video-empty d-flex flex-row gap-3 pa-3"
      @click="openMenu"
    >
      <v-icon color="#75746F">mdi-video-outline</v-icon>
      <div>{{ $t('blockTypes.video.subtitle') }}</div>
    </div>
    <div
      v-if="videoUrl"
      class="video-container"
    >
      <!-- <div class="video-overlay" @click="togglePlay"> TODO: remove this once the preventForwardSeeking is working
        <v-icon v-if="!isPlaying" size="64" color="white">mdi-play-circle</v-icon>
      </div> -->
      <video
        ref="videoPlayer"
        :src="videoUrl"
        class="video-player"
        :disablePictureInPicture="isRequired"
        playsinline
        webkit-playsinline
        controlsList="nodownload noremoteplayback noplaybackrate"
        :disableRemotePlayback="isRequired"
        @play="isPlaying = true"
        @pause="isPlaying = false"
        @ended="onVideoEnded"
        @seeking="preventForwardSeeking"
        @timeupdate="updateLastTime"
        controls
      />
     
    </div>
    <v-alert
        v-if="showError"
        type="error"
        dense
        text
        class="mt-2"
        >
        {{ $t('blocks.inputBlock.needToViewToEnd') }}
      </v-alert>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :position-x="menuX"
      :position-y="menuY"
      min-width="320px"
    >
      <v-card>
        <v-card-title>{{ $t('blocks.video.menuTitle') }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="videoInput"
            :label="$t('blocks.video.inputLabel')"
            outlined
          />
        </v-card-text>
        <v-card-actions class="d-flex flex-row justify-end">
          <v-btn
            @click="setVideo"
            :loading="loading"
            text
          >{{ $t('dialogs.saveButton') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import MenuItem from '@/components/MenuItem.vue'

export default {
  props: {
    blockUri: {
      type: String
    },
    editorMode: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      showMenu: false,
      videoInput: '',
      loading: false,
      menuX: 0,
      menuY: 0,
      isPlaying: false,
      lastValidTime: 0,
      showError: false
    }
  },
  watch: {
    videoUrl: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.videoInput = newVal
        } else {
          this.videoInput = ''
        }
      }
    }
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    videoUrl() {
      return this.block?.video
    },
    isRequired: {
      get() {
        return this.block?.required
      },
      set(value) {
        this.block.setRequired(value)
      }
    }
  },
  methods: {
    openMenu(event) {
      this.menuX = event.clientX
      this.menuY = event.clientY
      this.showMenu = true
    },
    async setVideo() {
      this.loading = true
      try {
        if (this.videoInput && this.videoInput.length > 0) {
          this.block.setVideo(this.videoInput)
        } else {
          this.block.setVideo(null)
        }
        await this.block.updateValue()
      } finally {
        this.loading = false
        this.showMenu = false
      }
    },
    togglePlay() {
      const video = this.$refs.videoPlayer
      if (video == null) {
        return
      }
      if (video.paused) {
        video.play()
      } else {
        video.pause()
      }
    },
    onVideoEnded() {
      this.isPlaying = false
      this.block.hasVideoEnded = true
    },
    preventForwardSeeking() {
      if (!this.isRequired) {
        return
      }
      const video = this.$refs.videoPlayer
      if (!video) return
      
      // If trying to seek forward, prevent it
      if (video.currentTime > this.lastValidTime) {
        video.currentTime = this.lastValidTime
      }
    },
    updateLastTime() {
      const video = this.$refs.videoPlayer
      if (!video) return
      
      // Only update the last valid time if we're actually playing
      // This prevents the lastValidTime from updating during seek attempts
      if (!video.seeking) {
        this.lastValidTime = video.currentTime
      }
    },
    validateAndUpdate() {
      if (!this.block.validate()) {
        this.showError = true
        this.$emit('validationError', true)
      } else {
        this.showError = false
        this.$emit('validationError', false)
      }
    },
  },
  components: {
    BaseBlock,
    MenuItem
  }
}
</script>

<style lang="scss" scoped>
.video-empty {
  background: #F2F1EE;
  color: #75746F;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.video-container {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16/9;
  // max-height: 400px;
}

.video-player {
  width: 100%;
  height: 100%;
  // max-height: 400px;
  background: black;
  position: relative;
  z-index: 0;
  object-fit: contain;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  .v-icon {
    opacity: 0.9;
    transform: scale(1);
    transition: all 0.3s ease;
  }

  &:hover .v-icon {
    opacity: 1;
    transform: scale(1.1);
  }
}
</style>
