<template>
  <BaseFormInput v-bind="$props" :hide-label="disabled && model">
    <template v-if="disabled && model">
      <v-btn
        text
        :color="buttonColor"
        @click="openUri"
        class=""
      >
        <v-icon small class="mr-2">mdi-open-in-new</v-icon>
        {{ buttonText }}
      </v-btn>
    </template>
    <v-text-field
      v-else
      :label="fieldStyle.labelAbove ? null : label"
      :rules="[rules.required, rules.schema, uriRule]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      @blur="finishEditing"
      v-model="model"
      :color="color"
      :disabled="disabled"
      v-bind="fieldStyle"
    />
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import uriInputMixin from '@/mixins/uriInputMixin.js'
import BaseFormInput from '@/components/form/BaseFormInput.vue'

export default {
  mixins: [formFields, uriInputMixin],
  data() {
    return {
      uriRule: v => (v == null || /.+:.+/.test(v)) || this.$t('validation.uri')
    }
  },
  computed: {
    buttonText() {
      return this.label || this.$t('common.open')
    }
  },
  methods: {
    openUri() {
      if (this.model) {
        window.open(this.model, '_blank')
      }
    }
  },
  components: {
    BaseFormInput
  }
}
</script>
