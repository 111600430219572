<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode">
    <template v-slot:menu>
      <MenuItem
        icon="mdi-cog-outline"
        :text="$t('blocks.externalHookButtonBlock.menuButton')"
        @click="openMenu"
      />
    </template>
    <div>
      <div
      v-if="!resource"
      class="resource-empty d-flex flex-row gap-3 pa-3"
      @click="openMenu"
    >
      <v-icon color="#75746F">mdi-button-cursor</v-icon>
      <div>{{ $t('blockTypes.externalHookButtonBlock.subtitle') }}</div>
    </div>
    <div v-else>
      <v-btn color="primary" rounded @click="buttonClicked">
        {{ this.block.resourceBlock.resource.name }}
      </v-btn>
    </div>
    </div>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :position-x="menuX"
      :position-y="menuY"
      min-width="320px"
    >
      <v-card>
        <v-card-title>{{ $t('blocks.externalHookButtonBlock.menuTitle') }}</v-card-title>
        <v-card-text>

      <TemplateResourceInput
        v-model="resource"
        :placeholder="$t('blocks.externalHookButtonBlock.inputLabel')"
        :block="block"
        v-bind="$attrs"
        v-on="$listeners"
        ref="resourcePicker"
        :loadItems="loadResources"
      />
      </v-card-text>
      </v-card>
    </v-menu>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import { mapGetters } from 'vuex'
import MenuItem from '@/components/MenuItem.vue'
import resourceInputMixin from '@/mixins/resourceInputMixin'
import TemplateResourceInput from '@/components/flow/TemplateResourceInput.vue'


export default {
  mixins: [ resourceInputMixin ],
  props: {
    blockUri: {
      type: String,
      required: true
    },
    editorMode: {
      type: Boolean,
      default: false
    },
    spaceUri: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showMenu: false,
      menuX: 0,
      menuY: 0,
      loading: false,
      errorMessage: null,
    }
  },
  computed: {
    ...mapGetters(['blockWithUri']),
    resource: {
      get() {
        return this.block.resourceBlock.resource
      },
      set(newVal) {
        this.handleItemSelected(newVal)
      }
    },
    block() {
      return this.blockWithUri(this.blockUri)
    },
    resourceLink() {
      return this.block?._links?.resources?.href
    },
  },
  components: {
    BaseBlock,
    MenuItem,
    TemplateResourceInput
  },
  methods: {
    openMenu(event) {
      this.menuX = event.clientX
      this.menuY = event.clientY
      this.showMenu = true
    },
    async handleItemSelected(newVal) {
      // Implement this method to handle the selected item from the resource picker

      this.loading = true
      try {
        this.block.resourceUri = newVal
        await this.block.updateValue()
      } finally {
        this.loading = false
        this.showMenu = false
      }
    },
    buttonClicked() {
      console.log('buttonClicked', this.resource)
      const redirectUri = this.block.resourceBlock.resource.redirectUri
      console.log('redirectUri', redirectUri)
      if (redirectUri) {
        window.open(`${window.location.origin}${redirectUri}`, '_blank')
      }
    },
  }
}
</script>

<style scoped>
.resizable-container {
  resize: vertical;
  min-height: 350px;
  overflow: auto;
}
/* Add styles for the resource block */
.resource-empty {
  cursor: pointer;
  background: #F2F1EE;
  color: #75746F;
  border-radius: 12px;
  max-width: 450px;
  cursor: pointer;
}
</style>
