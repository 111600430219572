<template>
  <v-sheet color="white" elevation="1" rounded class="pa-4">
    <div class="d-flex flex-column">
      <div v-if="title" class="no-text-overflow font-weight-medium">{{title}}</div>
      <div v-else class="no-text-overflow font-weight-medium grey--text">{{$t('kanban.unnamedEntry')}}</div>

        <component
          ref="component"
          v-for="(attribute, index) in nonFirstAttributes"
          class="mt-2"
          :key="index"
          :attribute="attribute"
          :hideFieldName="attribute.hideFieldName"
          :is="component(attribute)"
          v-bind="$attrs"
          :hideTypeIcon="true"/>
    </div>
    <div class="caption text-right grey--text mb-n3 mr-n2" v-if="devMode">{{ this.entity.position }}</div>
</v-sheet>
</template>

<script>
import { columnTypes } from '@/constants/columnTypes'

export default {
  props: ['entity'],
  computed: {
    title() {
      if (this.entity.attributes[0]?.property.name !== columnTypes.richText.name) {
        return this.entity.attributes[0]?.value
      }
      // Rich text is converted to plain text when used as a title
      var tempDiv = document.createElement('div')
      tempDiv.innerHTML = this.entity.attributes[0]?.value
      const title = tempDiv.textContent || tempDiv.innerText || ''
      return title
    },
    nonFirstAttributes() {
      return this.entity.attributes.slice(1)
    },
    devMode() {
      return this.$store.getters.developerMode
    },
  },
  methods: {
    component(attribute) {
      return attribute.property.kanbanAttributeComponent
    },
  }
}
</script>

<style scoped>
.no-text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
