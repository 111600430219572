<template>
<v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    :nudge-width="$vuetify.breakpoint.mobile ? 300 : 0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-1"
        :color="buttonColor"
        v-bind="attrs"
        v-on="on"
        text
        :style="buttonBackground"
        small
        data-testid="gridSortingMenuActivator"
      >
        <v-icon small class="mr-sm-2">mdi-sort-alphabetical-ascending</v-icon>
        <span v-if="label" class="d-none d-sm-flex">{{buttonText}}</span>
      </v-btn>
    </template>

    <v-card class="overflow-auto pa-5 d-flex flex-column gap-3">
      <div v-for="(criteria, i) in sortingCriterias" :key="i" class="d-flex">
        <SingleCriteriaSorting
          :disabled="disabled"
          :virtualGrid="virtualGrid"
          :sorting="criteria"
          @sortingChanged="(sorting) => sortingChanged(sorting, i)"
          @delete="() => removeSorting(i)"
          :data-testid="`singleCriteriaSorting-${i}`"
        />
      </div>
      <v-btn
        :disabled="!canAddMore || disabled"
        @click="addCriteria"
        color="primary"
        text
        small
        data-testid="addMoreSorting"
      >
        <v-icon small class="mr-sm-2">mdi-plus</v-icon>
        <span>{{$t('sorting.addCriteria')}}</span>
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script>
import colors from '@/constants/colors.js'
import SingleCriteriaSorting from '@/components/gridView/sorting/SingleCriteriaSorting.vue'

export default {
  props: {
    virtualGrid: null,
    disabled: null,
    label: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      menu: false,
      sortingCriterias: []
    }
  },
  watch: {
    'virtualGrid.sorting': {
      immediate: true,
      handler(newVal) {
        if (!this.isActive) {
          this.sortingCriterias = [undefined]
          return
        }

        if (Array.isArray(newVal)) {
          this.sortingCriterias = [...newVal]
        } else {
          this.sortingCriterias = [newVal]
        }
      }
    }
  },
  computed: {
    isActive() {
      return this.virtualGrid?.isSorted()
    },
    buttonColor() {
      return this.isActive ? 'primary' : 'accent'
    },
    buttonBackground() {
      return {
        background: this.isActive ? colors.sorting : undefined
      }
    },
    sortedColumnIds() {
      return this.virtualGrid?.sortedColumnIds() ?? []
    },
    canAddMore() {
      return (!this.sortingCriterias.length || this.sortingCriterias[this.sortingCriterias.length - 1] !== undefined) &&
        this.virtualGrid?.fields.some(field => !this.sortedColumnIds.includes(field.id))
    },
    buttonText() {
      return this.$tc('sorting.menuButtonLabel', this.sortedColumnIds.length)
    }
  },
  methods: {
    sortingChanged(criteria, index) {
      const sorting = this.sortingCriterias
      sorting[index] = criteria
      this.virtualGrid.setSorting(sorting)
    },
    removeSorting(index) {
      const sorting = [...this.sortingCriterias]
      sorting.splice(index, 1)
      this.virtualGrid.setSorting(sorting)
    },
    addCriteria() {
      this.sortingCriterias.push(undefined)
    }
  },
  components: {
    SingleCriteriaSorting
  }
}
</script>

<style scoped>
.gap-3 {
  gap: 12px;
}

.overflow-auto {
  overflow: auto;
}
</style>