<template>
  <BaseStatefulView
    :persistentGridUri="gridUri"
    :virtualGridUri="virtualGridUri"
    :viewTitle="$t(`calendar.title`)"
    @reloadView="reloadView"
    :showToolbar="showToolbar"
    :embedded="embedded"
    :autoreload="autoreload"
  >
    <template v-slot:toolbar-left v-if="!loading && virtualGrid">
      <CalendarStateSelectionMenu v-if="canPatchView" :calendarView="statefulView" />
      <VirtualGridToolbar :virtualGrid="virtualGrid" :statefulViewUri="statefulViewUri" :labels="toolbarLabels" />
    </template>

    <div
      v-if="!loading && virtualGrid && calendarModel == null"
      class="d-flex full-height justify-center align-center"
    >
      <CalendarStateSelection v-if="canPatchView" :calendarView="statefulView" />
    </div>

    <div
      v-else
      class="calendar-wrapper d-flex flex-column"
      :class="{ 'pa-5': !embedded }"
      :style="style"
    >
      <div
        class="calendar-header pa-3"
      >
      <div class="text-subtitle-2 ml-1 mb-1" v-if="$vuetify.breakpoint.mobile">
        {{ calendarTitle }}
      </div>
        <div class="d-flex d-flex justify-space-between align-center">

        <div class="text-subtitle-2" v-if="!$vuetify.breakpoint.mobile">
          {{ calendarTitle }}
        </div>
        <v-btn-toggle
          v-model="type"
          mandatory
          rounded
          dense
          selected="3"
          color="grey"
        >
          <v-btn
            v-for="type in types"
            :key="type"
            small
            class="font-weight-bold"
            color="white"
          >
            {{ $t(`calendar.types.${type}`) }}
          </v-btn>
        </v-btn-toggle>
        <div>
          <v-btn icon small class="ma-1" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn class="pl-1 pr-1" text @click="setToday()">
            {{ $t('calendar.today') }}
          </v-btn>
          <v-btn icon small class="ma-1" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
      <div class="flex-grow-1 calendar-body">
        <v-calendar
          v-if="virtualGrid && calendarModel"
          class="calendar"
          ref="calendar"
          :locale="_i18n.locale"
          v-model="value"
          :weekdays="weekday"
          :type="types[type]"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="event => event.color"
          @change="updateTitle"
          @click:event="editEntityOfEvent"
          @mousedown:event="onEventMousedown"
          @mousedown:time="onTimeMousedown"
          @mousemove:time="mouseMove"
          @mousemove:day="mouseMoveDay"
          @mouseup:time="saveAndClearState"
          @mouseup:day="saveAndClearState"
          @mouseleave.native="saveAndClearState"
        >
          <template v-slot:event="{ event, timed, eventSummary }">
            <!-- vuetify 2.6.10 breaking change :
            https://github.com/vuetifyjs/vuetify/releases/tag/v2.6.10 -->
            <component v-if="timed"  class="pl-1 v-event-draggable" :is="{ render: eventSummary }"/>
            <div v-else class="pl-1 v-event-draggable"><b>{{event.name}}</b></div>
            <div
              v-if="calendarModel.hasTime && hasEnd && type !== 2"
              class="v-event-drag-bottom"
              @mousedown.stop="startBottomDrag(event)"
            ></div>
          </template>
          <template v-slot:day-label="slotProps">
            <div
              class="d-flex justify-center"
              @mouseenter="hoveredDay = slotProps.date"
              @mouseleave="hoveredDay = undefined"
            >
              <div :style="slotProps.present && todayMarkerStyle" @click="toDayView(slotProps.date)">{{ slotProps.day }}</div>
              <v-btn
                v-show="slotProps.date === hoveredDay"
                :disabled="!canAddEntity"
                class="add-day-event"
                @click="addDayEntry(slotProps.date)"
                icon
                x-small
                data-testid="addDayEntryButton"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:day-label-header="slotProps">
            <div
              class="d-flex justify-center"
              @mouseenter="hoveredDay = slotProps.date"
              @mouseleave="hoveredDay = undefined"
            >
              <div :style="slotProps.present && todayMarkerStyle">{{ slotProps.day }}</div>
              <v-btn
                v-if="!calendarModel.hasTime"
                v-show="slotProps.date === hoveredDay"
                :disabled="!canAddEntity"
                class="add-day-event"
                @click="addDayEntry(slotProps.date)"
                icon
                x-small
                data-testid="addDayEntryButton"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:day="tms">
            <div
              class="fill-slot"
              @mouseenter="hoveredDay = tms.date"
              @mouseleave="hoveredTms = undefined"
            />
          </template>
        </v-calendar>
      </div>
    </div>
    <FullHeightLoader v-model="loading" />
    <EditEntityDialog
      :virtualGrid="virtualGrid"
      :entity="selectedEntity"
      v-model="editEntityDialog"
    />
  </BaseStatefulView>
</template>

<script>
import BaseStatefulView from '@/views/BaseStatefulView.vue'
import FullHeightLoader from '@/components/FullHeightLoader.vue'
import EditEntityDialog from '@/components/gridView/EditEntityDialog.vue'
import CalendarStateSelection from '@/components/calendar/CalendarStateSelection.vue'
import CalendarStateSelectionMenu from '@/components/calendar/CalendarStateSelectionMenu.vue'
import VirtualGridToolbar from '@/components/gridView/VirtualGridToolbar.vue'
import moment from 'moment'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'
import viewProps from '@/mixins/viewProps'
const states = {
  IDLE: 'IDLE',
  MOUSEDOWN_EVENT: 'MOUSEDOWN_EVENT',
  DRAGGING_EVENT: 'DRAGGING_EVENT',
  DRAGGING_NEW_EVENT: 'DRAGGING_NEW_EVENT',
  MOUSEDOWN_BOTTOM: 'MOUSEDOWN_BOTTOM',
  DRAGGING_BOTTOM: 'DRAGGING_BOTTOM'
}

export default {
  name: 'CalendarView',
  mixins: [viewProps],
  data() {
    return {
      calendarTitle: '',
      weekday: [1, 2, 3, 4, 5, 6, 0],
      type: 2,
      mode: 'stack',
      value: '',
      types: ['day', 'week', 'month'],
      toolbarHeight: 50,
      loading: false,
      selectedEntity: undefined,
      editEntityDialog: false,
      state: states.IDLE,
      dragEvent: null,
      dragToStartDiff: null,
      dragDate: null,
      hoveredDay: undefined,
      hoveredTms: undefined,
      provisionalEvent: undefined
    }
  },
  beforeDestroy() {
    this.virtualGrid.setLocalEntityParams(undefined)
  },
  watch: {
    type: {
      handler(newVal) {
        if (newVal < 2) {
          this.$nextTick(() => {
            this.$refs.calendar.scrollToTime('07:30')
          })
        }
      }
    },
    statefulViewUri: {
      immediate: true,
      async handler() {
        this.loading = true
        try {
          const statefulView = await this.$store.dispatch(
            'AGReadStatefulViewOperation',
            this.statefulViewUri
          )
          await this.$store.dispatch('AGReadVirtualGridOperation', {
            virtualGridUri: statefulView.parentGridUri,
            loadEntities: false
          })
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    canPatchView() {
      return hasPermission(this.statefulView, [PERMISSIONS.patch])
    },
    calendarModel() {
      return this.statefulView?.calendarModel()
    },
    events() {
      const events = [...(this.calendarModel?.events || [])]
      if (this.provisionalEvent) {
        events.push(this.provisionalEvent)
      }
      return events
    },
    hasEnd() {
      return this.calendarModel?.endField
    },
    virtualGrid() {
      return this.statefulView?.parentGrid
    },
    virtualGridUri() {
      return this.statefulView?.parentGridUri
    },
    style() {
      return {
        'max-height': `calc(100vh - ${this.$vuetify.application.top +
          this.toolbarHeight}px)`
      }
    },
    todayMarkerStyle() {
      return {
        'background': this.$vuetify.theme.themes.light.primary,
        'color': 'white',
        'width': '24px',
        'height': '24px',
        'border-radius': '100%'
      }
    },
    canAddEntity() {
      return hasPermission(this.virtualGrid, [PERMISSIONS.addEntity])
    },
    canUpdateFieldKey() {
      return hasPermission(this.virtualGrid, [PERMISSIONS.updateFieldKey])
    }
  },
  provide() {
    return {
      canUpdateFieldKey: () => this.canUpdateFieldKey,
      space: this.space
    }
  },
  methods: {
    updateTitle(changeEvent) {
      this.calendarTitle = this.$refs.calendar.title
      const start = this.toJsDate(changeEvent.start)
      const end = this.toJsDate(changeEvent.end)
      const filter = this.statefulView.entityFilter(start, end)
      this.virtualGrid.setLocalEntityParams({filter})
      this.virtualGrid.loadEntities()
    },
    setToday() {
      this.value = ''
    },
    toDayView(date) {
      this.value = date
      this.type = 0
    },
    editEntityOfEvent(event) {
      if (this.state === states.MOUSEDOWN_EVENT) {
        this.editEntity(event.event.entity)
        this.state = states.IDLE
      }
    },
    editEntity(entity) {
      this.selectedEntity = entity
      this.editEntityDialog = true
    },
    onEventMousedown({ event }) {
      if (event) {
        this.dragEvent = event
        this.state = states.MOUSEDOWN_EVENT
      }
    },
    onTimeMousedown(tms) {
      const mouseDate = this.toJsDate(tms)
      if (this.dragEvent && this.dragToStartDiff === null) {
        this.dragToStartDiff = mouseDate - this.dragEvent.start
      } else if (this.calendarModel?.hasTime) {
        this.provisionalEvent = {
          name: this.$t('calendar.newEvent'),
          color: 'accent',
          start: this.toJsDate(tms),
          end: undefined,
          timed: true
        }
        this.dragEvent = this.provisionalEvent
        this.state = states.DRAGGING_NEW_EVENT
      }
    },
    startBottomDrag(event) {
      this.dragEvent = event
      this.state = states.MOUSEDOWN_BOTTOM
    },
    mouseMove(tms) {
      const mouseDate = this.toJsDate(tms)
      // if (this.dragEvent && this.dragToStartDiff !== null && !this.provisionalEvent) {
      if (
        this.state === states.MOUSEDOWN_EVENT ||
        this.state === states.DRAGGING_EVENT
      ) {
        const start = this.dragEvent.start
        const newStart = new Date(mouseDate.getTime() - this.dragToStartDiff)
        const timeDiff = Math.abs(newStart - start)
        // Only update if time difference is at least one minute
        if (timeDiff > 60 * 1000) {
          this.state = states.DRAGGING_EVENT
          this.dragEvent.start = newStart
          this.virtualGrid.entityChanged(
            this.dragEvent.entity,
            this.dragEvent.start.toISOString(),
            this.calendarModel.startField
          )

          const end = this.dragEvent.end
          if (end) {
            const duration = end - start
            const newEnd = newStart.getTime() + duration
            this.dragEvent.end = new Date(newEnd)
            this.virtualGrid.entityChanged(
              this.dragEvent.entity,
              this.dragEvent.end.toISOString(),
              this.calendarModel.endField
            )
          }
        }
      }

      // if (this.dragEvent && this.provisionalEvent && this.calendarModel.endField) {
      if (this.state === states.DRAGGING_NEW_EVENT) {
        if (
          !this.provisionalEvent.end ||
          this.dateDiff(this.provisionalEvent.end, mouseDate) > 6000
        ) {
          this.provisionalEvent.end = mouseDate
        }
      }

      if (
        this.state === states.MOUSEDOWN_BOTTOM ||
        this.state === states.DRAGGING_BOTTOM
      ) {
        if (
          !this.dragEvent.end ||
          this.dateDiff(this.dragEvent.end, mouseDate) > 6000
        ) {
          this.state = states.DRAGGING_BOTTOM
          this.dragEvent.end = mouseDate
          this.virtualGrid.entityChanged(
            this.dragEvent.entity,
            this.dragEvent.end.toISOString(),
            this.calendarModel.endField
          )
        }
      }
    },
    mouseMoveDay(tms) {
      if (this.dragEvent && this.type !== 0) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end

        // Only update if the date is different
        const newMoment = new moment(this.dragEvent.start)
          .month(tms.month - 1)
          .date(tms.day)
        if (
          this.state === states.MOUSEDOWN_EVENT ||
          this.state === states.DRAGGING_EVENT
        ) {
          if (
            newMoment.dayOfYear() !==
            new moment(this.dragEvent.start).dayOfYear()
          ) {
            this.state = states.DRAGGING_EVENT
            this.dragEvent.start = new Date(newMoment.toISOString())
            console.log(this.dragEvent.start)
            this.virtualGrid.entityChanged(
              this.dragEvent.entity,
              newMoment.toISOString(),
              this.calendarModel.startField
            )

            if (end) {
              const duration = end - start
              const newEnd = newMoment.valueOf() + duration
              this.dragEvent.end = new Date(newEnd)
              this.virtualGrid.entityChanged(
                this.dragEvent.entity,
                this.dragEvent.end.toISOString(),
                this.calendarModel.endField
              )
            }
          }
        }
      }

      if (
        this.state === states.MOUSEDOWN_BOTTOM ||
        this.state === states.DRAGGING_BOTTOM
      ) {
        const newMoment = new moment(this.dragEvent.start)
          .month(tms.month - 1)
          .date(tms.day)
        if (
          newMoment.dayOfYear() !== new moment(this.dragEvent.end).dayOfYear()
        ) {
          this.state = states.DRAGGING_BOTTOM
          this.dragEvent.end = new Date(newMoment)
          this.virtualGrid.entityChanged(
            this.dragEvent.entity,
            this.dragEvent.end.toISOString(),
            this.calendarModel.endField
          )
        }
      }
    },
    saveAndClearState() {
      if (
        this.state === states.DRAGGING_EVENT ||
        this.state === states.DRAGGING_BOTTOM
      ) {
        const entity = this.dragEvent.entity
        this.virtualGrid.updateEntity(entity)
      }

      if (this.state === states.DRAGGING_NEW_EVENT) {
        this.addEntityWithStartDate(
          this.provisionalEvent.start.toISOString(),
          this.provisionalEvent.end?.toISOString()
        )
      }

      if (this.state !== states.MOUSEDOWN_EVENT) {
        this.state = states.IDLE
      }
      this.dragToStartDiff = null
      this.dragEvent = null
      this.dragDate = null
      this.provisionalEvent = undefined
    },
    toJsDate(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute)
    },
    dateDiff(a, b) {
      return Math.abs(a.getTime() - b.getTime())
    },
    async addDayEntry(date) {
      const dateString = new moment(date).toISOString()
      this.addEntityWithStartDate(dateString)
    },
    async addIntervalEntry(tms) {
      const dateString = this.toJsDate(tms).toISOString()
      this.addEntityWithStartDate(dateString)
    },
    async addEntityWithStartDate(dateString, endDateString) {
      const entity = await this.virtualGrid.emptyEntity()
      this.virtualGrid.entityChanged(
        entity,
        dateString,
        this.calendarModel.startField
      )
      if (endDateString) {
        this.virtualGrid.entityChanged(
          entity,
          endDateString,
          this.calendarModel.endField
        )
      }
      const fetchedEntity = await this.virtualGrid.addEntity(entity)
      this.editEntity(fetchedEntity)
    },
    reloadView() {
      this.virtualGrid.reload()
      this.statefulView.reload()
    }
  },
  components: {
    BaseStatefulView,
    FullHeightLoader,
    EditEntityDialog,
    CalendarStateSelection,
    CalendarStateSelectionMenu,
    VirtualGridToolbar
  }
}
</script>

<style lang="scss" scoped>
.calendar-wrapper {
  height: 100%;
  overflow: hidden;
}

.calendar-body {
  overflow-y: auto;
  & > div {
    max-width: calc(100% - 1px);
    border-top: none;
  }
}

.add-day-event {
  position: absolute;
  top: 4px;
  right: 4px;
}

.fill-slot {
  height: 100%;
  width: 100%;
}

.relative {
  position: relative;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
}
.v-event-drag-bottom::after {
  display: none;
  position: absolute;
  left: 50%;
  height: 4px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 16px;
  margin-left: -8px;
  opacity: 0.8;
  content: '';
}

.v-event-drag-bottom:hover::after {
  display: block;
}

.full-height {
  height: 100%;
  background-color: white;
}

.calendar-header {
  background: white;
  border: #e0e0e0 1px solid;
}

</style>
