<template>

  <div style="height:100%;">

    <div style="height:100%;" class="d-flex flex-row" >

      <div v-if="!loading" :style="{ height: '100%', width: '100%', 'overflow-y': 'auto', 'min-height': 'calc(100% - 100px)' }" class="flex-shrink-1 bla-3">
        <router-view />
      </div>

      <FullHeightLoader v-model="loading" :style="{ width: '100%'}"/>

      <ApptivesDrawer v-if="developerMode" v-model="apptivesDrawer"/>

    </div>
  </div>
</template>

<script>
import FullHeightLoader from '@/components/FullHeightLoader.vue'
import ApptivesDrawer from '@/components/apptives/ApptivesDrawer.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'

export default {
  props: {
    gridUri: null,
    spaceUri: null
  },
  data: () => ({
    drawerWidth: 240,
    loading: false
  }),
  provide() {
    return {
      plan: () => this.plan,
      space: this.space,
    }
  },
  watch: {
    gridUri: {
      immediate: true,
      async handler(newVal) {
        this.loading = true
        try {
          const grid = await this.$store.dispatch('AGReadGridOperation', newVal)
          const listPromises = []
          if (this.canListForms) {
            listPromises.push(this.$store.dispatch('AGListFormsOperation', newVal))
          }
          if (this.canListViews) {
            listPromises.push(this.$store.dispatch('loadViewUris', newVal))
          }
          if (this.canListStateFulViews) {
            listPromises.push(this.$store.dispatch('AGListStatefulViewsOperation', grid))
          }
          document.title = `${this.space.title()} / ${this.grid.name}`
          Promise.all(listPromises)
         
        } finally {
          this.loading = false
        }
      }
    },
  },
  computed: {
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    plan() {
        return this.space?.plan
    },
    developerMode() {
        return this.$store.getters.developerMode
    },
    canListForms() {
      return hasPermission(this.grid, [PERMISSIONS.forms])
    },
    canListViews() {
      return hasPermission(this.grid, [PERMISSIONS.views])
    },
    canListStateFulViews() {
      return hasPermission(this.grid, [PERMISSIONS.sviews])
    },
    apptivesDrawer: {
      get() {
        return this.$store.state.apptivesDrawer
      },
      set(newVal) {
        this.$store.commit('setApptivesDrawer', newVal)
      }
    },
  },
  methods: {
  },
  components: {
    FullHeightLoader,
    ApptivesDrawer,
  }
}
</script>
<style scoped>

</style>
